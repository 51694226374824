import { DateTime } from "luxon";

export default function groupImagesByDate(images) {
	const format = {
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	};
	const groupedImages = [];
	const dates = new Set();
	images.forEach((item) => {
		const itm = DateTime.fromISO(item.createdAt).toLocaleString(format);
		dates.add(itm);
	});

	images.forEach((image) => {
		const uploadDate = DateTime.fromISO(image.createdAt).toLocaleString(format);

		let group = groupedImages.find((g) => g.group === uploadDate);

		if (!group) {
			group = { group: uploadDate, images: [] };
			groupedImages.push(group);
		}

		group.images.push(image);
	});

	const today = DateTime.local().toLocaleString(format);
	const yesterday = DateTime.local()
		.minus({ days: 1 })

		.toLocaleString(format);

	const todayGroup = groupedImages.find((g) => g.group === today);
	if (!todayGroup) {
		groupedImages.unshift({ group: "Today", images: [] });
	}

	const yesterdayGroup = groupedImages.find((g) => g.group === yesterday);
	if (!yesterdayGroup) {
		groupedImages.splice(1, 0, { group: "Yesterday", images: [] });
	} // Add "Today" and "Yesterday" groups

	return groupedImages;
}
