"use client";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../api/app.api";
import logo from "../../assets/logo.png";
import { loginUser } from "../../store/actions/actions";

const api = new ApiService();
const LoginPage = () => {
	const [error, setError] = useState("");
	const [email, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		const body = JSON.stringify({
			email,
			password,
		});
		const response = await api.postRequest("api/v1/auth/signIn", body);
		if (!response.status) {
			setError(response.message);
			return;
		}
		let token = response.data.token;
		const me = response.data.user;
		const usr = { ...me, token: token, isVerified: true };
		dispatch(loginUser(usr));
		navigate("/");
	};

	return (
		<div className="min-h-[90vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
					{" "}
					<div className="sm:mx-auto sm:w-full sm:max-w-md mb-10">
						<img
							className="mx-auto h-20 w-auto"
							src={logo}
							alt="Logo"
							width={48}
							height={48}
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Sign in to your account
						</h2>
					</div>
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700">
								Username
							</label>
							<div className="mt-1">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									value={email}
									onChange={(event) => setUsername(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700">
								Password
							</label>
							<div className="mt-1">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									value={password}
									onChange={(event) => setPassword(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
								/>
							</div>
						</div>

						<div className="flex items-center justify-between">
							<div className="flex items-center">
								<input
									id="remember-me"
									name="remember-me"
									type="checkbox"
									checked={rememberMe}
									onChange={(event) => setRememberMe(event.target.checked)}
									className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
								/>
								<label
									htmlFor="remember-me"
									className="ml-2 block text-sm text-gray-900">
									Remember me
								</label>
							</div>

							<div className="text-sm">
								<Link
									to={"/verify-email"}
									className="font-medium text-indigo-600 hover:text-indigo-500">
									Forgot your password?
								</Link>
							</div>
						</div>
						{error && (
							<div className="mt-4">
								<div
									className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{error}</span>
								</div>
							</div>
						)}
						<div>
							<button
								type="submit"
								className="bg-emerald-500 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Sign in
							</button>
						</div>
					</form>
					<div className="mt-6">
						<div className="relative">
							<div
								className="absolute inset-0 flex items-center"
								aria-hidden="true">
								<div className="w-full border-t border-gray-300"></div>
							</div>
							<div className="relative flex justify-center text-sm">
								<span className="px-2 bg-white text-gray-500">Or sign up</span>
							</div>
						</div>

						<div className="flex items-center justify-between mt-6">
							<div>
								<p>You do not have an account?</p>
							</div>

							<div className="text-sm">
								<Link
									to={"/signup"}
									className="font-medium text-indigo-600 hover:text-indigo-500">
									create account
								</Link>
							</div>
							{/* <button
								// onClick={() => signIn("google")}
								className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
								<FaGoogle className="mr-2 h-5 w-5" /> Google
							</button>

							<button
								// onClick={() => signIn("facebook")}
								className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
								<FaFacebook className="mr-2 h-5 w-5" /> Facebook
							</button> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
