import React, { useEffect, useState } from "react";
import {
	useTable,
	useFilters,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
	usePagination,
} from "react-table";
import {
	ChevronDoubleLeftIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { Button, PageButton } from "../shared/button";
import { classNames } from "../shared/utils";
import { SortIcon, SortUpIcon, SortDownIcon } from "../shared/icons";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdOutlineClose, MdOutlineDelete } from "react-icons/md";
import DeleteConfirmationModal from "../../../components/delete_confirm";
import IconButton from "../../../components/icon_button";
import ApiService from "../../../api/app.api";
import UpdateModal from "../../../components/edit_modal";
import ViewModal from "../../../components/vie_modal";
import { getCountry } from "../../../utils/get_country";
import ViewInviteModal from "./Invite_view_modal";
import axios from "axios";
import UserOne from "../../../assets/empty.jpg";
import { useSelector } from "react-redux";
import * as moment from "moment";
//API handler
const api = new ApiService();

// Define a default UI for filtering
function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<label className="flex gap-x-2 items-baseline">
			<span className="text-gray-700">Search: </span>
			<input
				type="text"
				className="focus:ring focus:ring-opacity-50 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={`${count} records...`}
			/>
		</label>
	);
}

export const DateTimeCell = ({ value }) => {
	return (
		<div className="font-md">{moment(value).format("yyyy/MM/DD hh:mm")}</div>
	);
};

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	// Render a multi-select box
	return (
		<label className="flex gap-x-2 items-baseline">
			<span className="text-gray-700">{render("Header")}: </span>
			<select
				className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
				name={id}
				id={id}
				value={filterValue}
				onChange={(e) => {
					setFilter(e.target.value || undefined);
				}}>
				<option value="">All</option>
				{options.map((option, i) => (
					<option key={i} value={option}>
						{option}
					</option>
				))}
			</select>
		</label>
	);
}

export function StatusPill({ value }) {
	console.log("STATUS", value);
	const status = value ? "verified".toUpperCase() : "unverified".toUpperCase();

	return (
		<span
			className={classNames(
				"px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
				value ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800"
			)}>
			{status}
		</span>
	);
}
export function KStatusPill({ value }) {
	console.log("STATUS", value);
	const status = value ? "Completed".toUpperCase() : "InComplete".toUpperCase();

	return (
		<span
			className={classNames(
				"px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
				value ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800"
			)}>
			{status}
		</span>
	);
}
export function AvatarCell({ value, column, row }) {
	console.log("DTDT", row.original[column.lastnameAccessor]);
	const imageUrl = row.original[column.imgAccessor];
	return (
		<div className="flex items-center">
			<div className="flex-shrink-0 h-10 w-10">
				<img
					className="h-10 w-10 rounded-full"
					src={
						imageUrl
							? `https://shifa-bckt.s3.amazonaws.com/${imageUrl}`
							: UserOne
					}
					alt=""
				/>
			</div>
			<div className="ml-4">
				<div className="text-sm font-medium text-gray-900">{`${value} ${
					row.original[column.lastnameAccessor]
				}`}</div>
				<div className="text-sm text-gray-500">
					{row.original[column.emailAccessor]}
				</div>
			</div>
		</div>
	);
}
export function DuaArabicCell({ value }) {
	return (
		<div className="text-right">
			<p className="mb-2">{value}</p>
		</div>
	);
}

export function ActionCollumn({ __, row }) {
	const [isModalOpen, setModalOpen] = useState(false);
	const rw = row.original;
	const handleDelete = async () => {
		const res = await api.deleteRequest(`api/v1/duas/${rw.duaType}/${rw.id}`);
		console.log("RES", res);
		setModalOpen(false);
	};
	return (
		<div className="flex justify-around items-center">
			<ViewModal data={rw} />
			<UpdateModal data={rw} />

			<DeleteConfirmationModal
				isOpen={isModalOpen}
				onClose={() => setModalOpen(false)}
				onDelete={handleDelete}
				onOpen={() => setModalOpen(true)}
			/>
		</div>
	);
}
export function InviteActionCollumn({ __, row }) {
	const [isModalOpen, setModalOpen] = useState(false);
	const rw = row.original;
	const handleDelete = async () => {
		const res = await api.deleteRequest(`api/v1/duas/${rw.duaType}/${rw.id}`);
		console.log("RES", res);
		setModalOpen(false);
	};
	return (
		<div className="flex justify-around items-center">
			<ViewInviteModal invite={rw} />
			<UpdateModal data={rw} />

			<DeleteConfirmationModal
				isOpen={isModalOpen}
				onClose={() => setModalOpen(false)}
				onDelete={handleDelete}
				onOpen={() => setModalOpen(true)}
			/>
		</div>
	);
}

export function UserActionCollumn({ __, row }) {
	const [isModalOpen, setModalOpen] = useState(false);
	const rw = row.original;

	const handleUserDelete = async () => {
		const res = await api.deleteRequest(`api/v1/users/${rw.duaType}/${rw.id}`);
		console.log("RES", res);
		setModalOpen(false);
	};
	return (
		<div className="flex justify-around items-center">
			<ViewUserModal user={rw} />
			<UpdateUserRole user={rw} />
			{/*
			<DeleteConfirmationModal
				isOpen={isModalOpen}
				onClose={() => setModalOpen(false)}
				onDelete={handleUserDelete}
				onOpen={() => setModalOpen(true)}
			/> */}
		</div>
	);
}
const ViewUserModal = ({ user }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	return (
		<div>
			<IconButton
				icon={FaEye}
				label="view"
				onClick={() => openModal()}
				color="text-meta-3"
			/>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 lg:w-[50%] h-auto">
						<div className="flex justify-between">
							<h2 className="text-xl mb-4 text-center font-semibold">
								User information
							</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={() => {
									closeModal();
								}}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<hr />
						<div className="py-4 px-2">
							<div className="flex items-center">
								<div className="flex-shrink-0 h-40 w-40">
									<img
										className="h-40 w-40 rounded-full object-cover"
										src={`https://shifa-bckt.s3.amazonaws.com/${user.imageUrl}`}
										alt=""
									/>
								</div>
								<div className="ml-4">
									<div className="text-md font-medium text-slate-900">{`${user.firstname} ${user.lastname}`}</div>
									<div className="text-md text-slate-500">{user.email}</div>
								</div>
							</div>

							<div className="mt-4">
								<hr className="my-4" />
								<p className="py-2 px-2">
									{" "}
									Date of Birth:{" "}
									<span className="mx-4">{user.dateOfBirth}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Joined At:<span className="mx-4">{user.createdAt}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Verified:{" "}
									<span className="mx-4">
										<StatusPill value={user.isVerified} />
									</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Profile Pic:<span className="mx-4">{user.imageUrl}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Role:<span className="mx-4">{user.role.name}</span>{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const UpdateUserRole = ({ user }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState("");
	const [error, setError] = useState("");
	// const queryClient = useQueryClient();
	const me = useSelector((st) => st.user).user;
	const handleRoleChange = (event) => {
		setSelectedRole(event.target.value);
	};
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setError("");
	};
	const handleUpdate = async () => {
		//handle update user
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_API_URL}/api/v1/users/update-role`,
				{ user: user.id, role: selectedRole },
				{
					headers: {
						Authorization: `Bearer ${me.token}`,
					},
				}
			);
			console.log("RESPONSE", response.data);

			// handleRefetch();
			closeModal();
		} catch (e) {
			console.log("E", e);
			const err = e.response.data;
			if (err.statusCode === 401 || err.statusCode === 403) {
				setError("You are not authorized to perfom this operation");
			}
		}
	};
	// const handleRefetch = () => {
	// 	queryClient.invalidateQueries("tableData");
	// };
	return (
		<div>
			<IconButton
				icon={FaEdit}
				label="edit"
				onClick={() => openModal()}
				color="text-warning"
			/>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[50%] h-auto">
						<div className="flex justify-between">
							<h2 className="text-xl mb-4 text-center font-semibold">
								User information
							</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={() => {
									closeModal();
								}}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<hr />
						<div className="pt-2 pb-1 px-2">
							<div className="flex items-center">
								<div className="flex-shrink-0 h-40 w-40">
									<img
										className="h-40 w-40 rounded-full object-cover"
										src={`https://shifa-bckt.s3.amazonaws.com/${user.imageUrl}`}
										alt=""
									/>
								</div>
								<div className="ml-4">
									<div className="text-md font-medium text-slate-900">{`${user.firstname} ${user.lastname}`}</div>
									<div className="text-md text-slate-500">{user.email}</div>
								</div>
							</div>

							<div className="mt-4">
								<hr className="my-4" />
								<p className="py-2 px-2">
									{" "}
									Date of Birth:{" "}
									<span className="mx-4">{user.dateOfBirth}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Joined At:<span className="mx-4">{user.createdAt}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Verified:{" "}
									<span className="mx-4">
										<StatusPill value={user.isVerified} />
									</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Profile Pic:<span className="mx-4">{user.imageUrl}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Role:<span className="mx-4">{user.role.name}</span>{" "}
								</p>
							</div>
							<div className="mt-4">
								<hr className="my-4" />
								<DropdownSelect
									handleOptionChange={handleRoleChange}
									name="role"
									selectedOption={selectedRole}
									me={me}
								/>
							</div>
							{error && (
								<div className="py-2 px-1 bg-red-500 rounded-sm my-2">
									<p className="text-white">{error}</p>
								</div>
							)}
							<div className="flex justify-end ">
								<button
									className="uppercase text-white px-2 py-1 rounded my-2 mx-1 bg-gray-400 "
									onClick={closeModal}>
									cancel
								</button>
								<button
									className="uppercase text-white px-2 py-1 rounded my-2 mx-1 bg-emerald-500"
									onClick={handleUpdate}>
									Update
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const DropdownSelect = ({ selectedOption, handleOptionChange, name, me }) => {
	const [roles, setRoles] = useState([]);
	useEffect(() => {
		fetchRoles();
	}, []);
	const fetchRoles = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/v1/roles`,
				{
					headers: {
						Authorization: `Bearer ${me.token}`,
					},
				}
			);
			const data = await response.data.data.roles;
			console.log("USERSTATS", data);
			setRoles(data);
		} catch (error) {
			console.error("Failed to fetch user statistics:", error);
		}
	};
	return (
		<div className="flex items-center space-x-2">
			<label htmlFor="dropdown" className="text-sm font-medium text-gray-700">
				Select an option:
			</label>
			<select
				id="dropdown"
				name={name}
				value={selectedOption}
				onChange={handleOptionChange}
				className="appearance-none block w-[50%] px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
				<option value="">Select...</option>
				{roles.map((role) => (
					<option key={role.id} value={role.id}>
						{role.name}
					</option>
				))}
			</select>
		</div>
	);
};
export function CountryColum({ __, row }) {
	const rw = row.original;
	const country = getCountry(rw.country);
	return (
		<div>
			{" "}
			<p className="mb-2">{country}</p>
		</div>
	);
}

function Table({ columns, data }) {
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,

		state,
		preGlobalFilteredRows,
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
		},
		useFilters, // useFilters!
		useGlobalFilter,
		useSortBy,
		usePagination // new
	);

	// Render the UI for your table
	return (
		<>
			<div className="sm:flex sm:gap-x-2">
				<GlobalFilter
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
				/>
				{headerGroups.map((headerGroup) =>
					headerGroup.headers.map((column) =>
						column.Filter ? (
							<div className="mt-2 sm:mt-0" key={column.id}>
								{column.render("Filter")}
							</div>
						) : null
					)
				)}
			</div>
			{/* table */}
			<div className="mt-4 flex flex-col shadow-md">
				<div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table
								{...getTableProps()}
								className="min-w-full divide-y divide-gray-200">
								<thead className="bg-emerald-500 text-white">
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column) => (
												// Add the sorting props to control sorting. For this example
												// we can add them into the header props
												<th
													scope="col"
													className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
													{...column.getHeaderProps(
														column.getSortByToggleProps()
													)}>
													<div className="flex items-center justify-between">
														{column.render("Header")}
														{/* Add a sort direction indicator */}
														<span>
															{column.isSorted ? (
																column.isSortedDesc ? (
																	<SortDownIcon className="w-4 h-4 text-gray-400" />
																) : (
																	<SortUpIcon className="w-4 h-4 text-gray-400" />
																)
															) : (
																<SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
															)}
														</span>
													</div>
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody
									{...getTableBodyProps()}
									className="bg-white divide-y divide-gray-200">
									{page.map((row, i) => {
										// new
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return (
														<td
															{...cell.getCellProps()}
															className="px-6 py-4 whitespace-nowrap"
															role="cell">
															{cell.column.Cell.name === "defaultRenderer" ? (
																<div className="text-sm text-gray-500">
																	{cell.render("Cell")}
																</div>
															) : (
																cell.render("Cell")
															)}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{/* Pagination */}
			<div className=" py-3 flex items-center justify-between">
				<div className="sm:hidden flex-1 flex items-center justify-between">
					<Button onClick={() => previousPage()} disabled={!canPreviousPage}>
						Previous
					</Button>
					<Button onClick={() => nextPage()} disabled={!canNextPage}>
						Next
					</Button>
				</div>
				<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
					<div className="flex gap-x-2 items-baseline">
						<span className="text-sm text-gray-700">
							Page <span className="font-medium">{state.pageIndex + 1}</span> of{" "}
							<span className="font-medium">{pageOptions.length}</span>
						</span>
						<label>
							<span className="sr-only">Items Per Page</span>
							<select
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
								value={state.pageSize}
								onChange={(e) => {
									setPageSize(Number(e.target.value));
								}}>
								{[5, 10, 20].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										Show {pageSize}
									</option>
								))}
							</select>
						</label>
					</div>
					{/* <div> */}
					<nav
						className="inline-flex rounded-md shadow-sm -space-x-px "
						aria-label="Pagination">
						<PageButton
							className="rounded-l-md"
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}>
							<span className="sr-only">First</span>
							<ChevronDoubleLeftIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</PageButton>
						<PageButton
							onClick={() => previousPage()}
							disabled={!canPreviousPage}>
							<span className="sr-only">Previous</span>
							<ChevronLeftIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</PageButton>
						<PageButton onClick={() => nextPage()} disabled={!canNextPage}>
							<span className="sr-only">Next</span>
							<ChevronRightIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</PageButton>
						<PageButton
							className="rounded-r-md"
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}>
							<span className="sr-only">Last</span>
							<ChevronDoubleRightIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</PageButton>
					</nav>
					{/* </div> */}
				</div>
			</div>
		</>
	);
}

export default Table;
