import { useState, useRef } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import Avatar from "./Avatar";
import Card from "./Card";
import InitialsAvatar from "./InitialsAvatar";

export default function PostFormCard({ onPost }) {
  const [error, setError] = useState("");
  const profile = useSelector((st) => st.user).user;
  const fullName = profile.firstname + " " + profile.lastname;

  const contentRef = useRef("");

  //create post
  async function createPost() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/feeds`,
        {
          content: contentRef.current,
        },
        {
          headers: {
            Authorization: `Bearer ${profile.token}`,
          },
        }
      );

      if (!response.status) {
        setError(response.message);
        return;
      }
      contentRef.current = "";
      if (onPost) {
        onPost();
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        setError(
          "You are not authorized to perfom this action. Kindly contact the admin"
        );
      } else {
        setError("Something went wrong. Please try again later.");
      }
    }
  }

  console.log("FULL NAME", fullName);

  return (
    <Card>
      <div className="flex gap-2">
        <div>
          {profile.imageUrl ? (
            <Avatar
              url={`https://shifa-bckt.s3.amazonaws.com/${profile.imageUrl}`}
            />
          ) : (
            <InitialsAvatar
              firstname={profile.firstname}
              lastname={profile.lastname}
            />
          )}
        </div>

        <textarea
          name="content"
        //   value={contentRef.current}
          onChange={(e) => {
            contentRef.current = e.target.value;
          }}
          className="grow p-3 h-16 bg-gray-200 rounded text-gray-950 outline-none"
          placeholder={`Whats on your mind, ${fullName}?`}
        />
      </div>

      <div className="flex gap-5 items-center mt-2">
        <div className="grow text-right">
          <button
            onClick={createPost}
            className="bg-emerald-500 text-white px-6 py-1 rounded-md"
          >
            Share
          </button>
        </div>
      </div>
    </Card>
  );
}
