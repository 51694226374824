import React, { useEffect, useState } from "react";
import ApiService from "../../api/app.api";
import InputField from "../../components/input_field";
import Modal from "../../components/modal.component";
import Table, {
	ActionCollumn,
	DuaArabicCell,
	KStatusPill,
	SelectColumnFilter,
	StatusPill,
} from "./components/user_table";
import DashboardLayout from "../layouts/admin_layout";
import axios from "axios";
import { useSelector } from "react-redux";
import Breadcrumb from "../../components/bread_crumb";

const api = new ApiService();
export default function KhatamAlQuran() {
	const [projectName, setProjectName] = useState("");
	const [email, setEmail] = useState("");
	const [name, setNames] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [deceased, setDeceased] = useState("");
	const [data, setData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const user = useSelector((st) => st.user).user;

	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/v1/khatam-al-quran/all-projects`,
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			console.log("INVITES", response);
			const res = response.data.data.khatamAlQuran;
			setData(res);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Project Name",
				accessor: "projectName",
			},
			{
				Header: "Name",
				accessor: "name",
				// Cell: DuaArabicCell,
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Mobile Number",
				accessor: "mobileNumber",
			},
			{
				Header: "Deceased",
				accessor: "deceased",
			},
			{
				Header: "Status",
				accessor: "isCompleted",
				Cell: KStatusPill,
				Filter: SelectColumnFilter, // new
				filter: "includes",
			},
		],
		[]
	);
	// const data = React.useMemo(() => getDuas(), []);
	const handleCreateProject = async () => {
		const body = JSON.stringify({
			email,
			projectName,
			name,
			mobileNumber,
			deceased,
		});
		console.log("BODY", body);
		const data = await api.postRequestwithBearer(
			"api/v1/khatam-al-quran/start-project",
			body,
			user.token
		);
		console.log("DATA", data);
		fetchData();
		closeModal();
	};
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Khatam-Al-Quran", path: "//Khatam al Quaran" },
	];
	return <Table columns={columns} data={data} />;
}
