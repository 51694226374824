import React, { useState, useCallback } from "react";
import { FaFile } from "react-icons/fa";
import DropdownSelect from "./dropdown_select";
import ApiService from "../api/app.api";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Toast from "./toast";
import { MdOutlineClose } from "react-icons/md";
import IconButton from "./icon_button";

const api = new ApiService();
const UploadModal = ({ refetch, id, user }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [dragCounter, setDragCounter] = useState(0);
	const [files, setFiles] = useState(null);
	const [selectedOption, setSelectedOption] = useState("");
	const [selectedFiles, setSelectedFiles] = useState([]);

	const handleDragEnter = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragCounter(dragCounter + 1);
	};
	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragCounter(dragCounter - 1);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragCounter(0);
		const files = e.dataTransfer.files[0];
		setFiles(files);
	};

	const handleFileSelect = (e) => {
		const input = document.createElement("input");
		input.type = "file";
		input.multiple = true;
		input.click();
		input.addEventListener("change", (event) => {
			const files = event.target.files[0];
			console.log("FILE", files);
			setFiles(files);
		});
	};

	const handleDirectoryInputChange = (e) => {
		const files = Array.from(e.target.files);
		setFiles(files);
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleSumbit = async (e) => {
		e.preventDefault();
		const formData = new FormData();

		formData.append("csv", selectedFiles[0]);

		// const data = await api.multipartRequest(
		// 	`api/v1/duas/file/${selectedOption}`,
		// 	formData
		// );
		console.log(
			"URL",
			`http://localhost:5000/api/v1/duas/file/${selectedOption}`
		);

		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/api/v1/duas/file/${selectedOption}/${id}`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
		);
		console.log("RES", response);
		refetch();
		closeModal();
	};
	const onDrop = useCallback((acceptedFiles) => {
		setSelectedFiles(acceptedFiles);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
	return (
		<div>
			<button
				className="bg-emerald-500 p-2 text-white font-medium rounded-md uppercase"
				onClick={openModal}>
				Upload csv
			</button>

			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[50%]  h-auto ">
						<div className="flex justify-between">
							<h2 className="text-2xl font-bold mb-4">Upload File</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={closeModal}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<hr />
						<form onSubmit={handleSumbit}>
							<div className="py-4">
								<DropdownSelect
									name="duaType"
									selectedOption={selectedOption}
									handleOptionChange={handleOptionChange}
								/>
							</div>
							<input
								type="file"
								name="files"
								// value={selectedFiles[0]?.name}
								onChange={(e) => setSelectedFiles(e.target.files)}
							/>
							<div className="mt-6 flex justify-end">
								<button
									className="bg-gray-300 text-white font-bold py-2 px-8 rounded mr-2 uppercase"
									onClick={closeModal}>
									Close
								</button>
								<button
									className="bg-emerald-500 text-white font-bold py-2 px-8 rounded uppercase"
									type="submit">
									Upload
								</button>
							</div>
						</form>

						{/* <div
							{...getRootProps()}
							className={`border-2 border-dashed border-gray-400 p-4 rounded-md h-[70%] flex flex-col justify-center items-center ${
								isDragActive ? "bg-gray-200" : ""
							}`}>
							<input {...getInputProps()} />
							{isDragActive ? (
								<p>Drop the files here...</p>
							) : (
								<p>Drag and drop files here, or click to select files</p>
							)}
							<ul>
								{selectedFiles.map((file, index) => (
									<li key={index}>{file.name}</li>
								))}
							</ul>
						</div> */}
						{/* <div
							className={`border-4 border-dashed border-gray-400 rounded-lg py-4 h-[70%] flex flex-col justify-center items-center ${
								dragCounter > 0 ? "bg-gray-200" : ""
							}`}
							onDragEnter={handleDragEnter}
							onDragLeave={handleDragLeave}
							onDragOver={handleDragOver}
							onDrop={handleDrop}
							onClick={handleFileSelect}>
							<FaFile className="h-25 w-25" />
							{dragCounter > 0 ? (
								<p>Drop the files here...</p>
							) : (
								<p>Click to select files or Drag and drop files here...</p>
							)}
							{files && (
								<div className="flex flex-col">
									<p className="text-lg">{files?.name}</p>
								</div>
							)}
						</div>{" "} */}
					</div>
				</div>
			)}
		</div>
	);
};

export default UploadModal;
