import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ApiService from "../../../../api/app.api";
import folder from "../../../../assets/folder.png";
import Breadcrumb from "../../../../components/bread_crumb";
import CreateModal from "../../../../components/create_modal";
import DashboardLayout from "../../../layouts/admin_layout";
import UploadModal from "../../../../components/upload_modal";
import AddCategory from "../../../../components/add_category";
import { useSelector } from "react-redux";

const api = new ApiService();
export default function DuaChildren() {
	const [data, setData] = useState([]);
	const [category, setCat] = useState({});
	const location = useLocation();
	const user = useSelector((st) => st.user).user;
	const { id } = location.state;
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await api.getRequest(
				`api/v1/duas/categories/${id}`,
				user.token
			);
			const res = response.data.children;
			setData(res);
			delete response.data.children;
			setCat(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	// const data = React.useMemo(() => getDuas(), []);
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Duas", path: "/duas" },
		{ name: `${category.title}`, path: `/duas/${category.title}` },
	];
	return (
		<DashboardLayout>
			{" "}
			<div className="flex justify-between items-center mx-4 my-2">
				<Breadcrumb routes={routes} />
				<div className="flex  justify-between justify-items-center">
					<UploadModal id={id} refetch={fetchData} user={user} />
					{/* <AddCategory refetch={fetchData} /> */}
				</div>
			</div>
			<div className="flex flex-wrap mt-6 mx-8 ">
				{data.map((item, index) => (
					<div className="w-1/11 p-4" key={index}>
						<Link
							to={`/dua/${item.title}/${item.id}`}
							state={{ id: item.id, name: category.title }}>
							<div className=" rounded-md p-4">
								<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
								<p className="text-center whitespace-pre-line ">
									{item.title.substring(0, 21)}
								</p>
							</div>
						</Link>
					</div>
				))}
			</div>
		</DashboardLayout>
	);
}
const objects = [
	{ name: "text", instructions: "Step 1 " },
	{ name: "text", instructions: "Step 2" },
	{ name: " ", instructions: "step 2 cont.." },
	{ name: "text", instructions: "Step 4 " },
	{ name: "", instructions: "" },
	{ name: "text", instructions: "Step 6 " },
	{ name: "", instructions: "" },
	{ name: "text", instructions: "Step 7" },
	{ name: "", instructions: "Step 7 cont..." },
];

const newobjects = [
	{ name: "text", instructions: "Step 1 " },
	{ name: "text", instructions: "Step 2 step 2 cont.." },

	{ name: "text", instructions: "Step 4 " },

	{ name: "text", instructions: "Step 6 " },

	{ name: "text", instructions: "Step 7 Step 7 cont..." },
];
