export const loginUser = (data) => {
	console.log("DATA USER", data);
	return (dispatch) => dispatch({ data, type: "LOGIN_USER" });
};
export const updateUser = (data) => {
	console.log("DATA USER", data);
	return (dispatch) => dispatch({ data, type: "UPDATE_USER" });
};
export const logOut = () => {
	console.log("LOGING OUT");
	return (dispatch) => dispatch({ type: "LOG_OUT" });
};
