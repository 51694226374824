import { Link } from "react-router-dom";

export default function SideBarItem({
	name,
	icon,
	href,
	isActive,
	children,
	onClick,
	nestedIcon,
}) {
	return (
		<li className="m-2">
			<Link to={href} onClick={onClick}>
				{/* <a className="flex items-center px-4 py-2 text-sm font-medium"> */}
				<div
					className={
						"flex justify-start px-2 md:px-4 py-4 rounded items-center " +
							isActive ?? " bg-emerald-300"
					}>
					{" "}
					<div className="text-2xl">{icon}</div>
					<h6 className="font-medium  md:pl-4  text-md"> {name}</h6>
					{nestedIcon}
				</div>

				{/* </a> */}
			</Link>
			{children}
		</li>
	);
}
