import { COUNTRIES } from "../pages/lib/countries";

export const getCountry = (value) => {
	const formattedValue = value.toLowerCase().trim();
	const abbrv = value === value.toUpperCase();
	console.log("COUNTRY VAL", value);
	const foundCountry = COUNTRIES.find((country) => {
		let formattedValueMatch;
		let formattedTitleMatch;
		if (abbrv) {
			formattedValueMatch = country.value === value;
		} else {
			const formattedTitle = country.title.toLowerCase();

			formattedTitleMatch = formattedTitle.includes(formattedValue);
		}

		return abbrv ? formattedValueMatch : formattedTitleMatch;
	});
	return foundCountry ? foundCountry.title : "NULL";
};
