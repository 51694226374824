import { useState } from "react";
import ApiService from "../api/app.api";
import IconButton from "./icon_button";
import { MdOutlineClose } from "react-icons/md";

const api = new ApiService();
export default function Modal({
	children,
	handleSubmit,
	label,
	title,
	action,
	isModalOpen,
	setIsModalOpen,
	openModal,
	closeModal,
}) {
	// const [isModalOpen, setIsModalOpen] = useState(false);
	// const [selectedOption, setSelectedOption] = useState("");
	// const [engContent, setEnContent] = useState("");
	// const [editorContent, setEditorContent] = useState("");
	// const [title, setTitle] = useState("");
	// const [translatedText, setTranslatedText] = useState("");

	// const handleChange = (event) => {
	// 	setText(event.target.value);
	// };

	// const handleChangeArabic = (content) => {
	// 	setEditorContent(content);
	// };

	// const handleOptionChange = (event) => {
	// 	setSelectedOption(event.target.value);
	// };
	// const openModal = () => {
	// 	setIsModalOpen(true);
	// };

	// const closeModal = () => {
	// 	setIsModalOpen(false);
	// };
	// const handleTextChange = (event) => {
	// 	setEnContent(event.target.value);
	// 	// setTranslatedText(event.target.value);
	// };

	return (
		<div>
			<button
				className="bg-emerald-500 p-2 text-white font-medium rounded-md uppercase mx-4"
				onClick={openModal}>
				{label}
			</button>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[30%] ">
						<div className="flex justify-between">
							<h2 className="text-2xl font-bold mb-4">{title}</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={closeModal}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<hr />
						<div className="py-4 px-2">{children}</div>

						<div className="mt-6 flex justify-end">
							<button
								className="bg-gray-300 hover:bg-gray-400 text-white font-bold py-1 px-4 rounded mr-2 uppercase"
								onClick={closeModal}>
								Close
							</button>
							<button
								className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-1 px-4 rounded uppercase"
								onClick={handleSubmit}>
								{action}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
