import React from "react";

const DuaCard = ({ dua }) => {
	const { name, duaArabic, duaEnglish, translation } = dua;

	return (
		<div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-start">
			<h2 className="text-2xl font-bold mb-2">{name}</h2>
			<p className="text-gray-500 mb-4">{translation}</p>
			<div className="border-t border-gray-300 pt-4">
				<h3 className="text-lg font-semibold mb-2">Arabic Dua:</h3>
				<p>{duaArabic}</p>
			</div>
			<div className="border-t border-gray-300 pt-4">
				<h3 className="text-lg font-semibold mb-2">English Translation:</h3>
				<p>{duaEnglish}</p>
			</div>
		</div>
	);
};

export default DuaCard;
