import React, { useEffect, useState } from "react";
import Table, {
	ActionCollumn,
	DuaArabicCell,
	SelectColumnFilter,
} from "./components/user_table";
import UploadModal from "../../components/upload_modal";
import ApiService from "../../api/app.api";
import CreateModal from "../../components/create_modal";
import DashboardLayout from "../layouts/admin_layout";
import Breadcrumb from "../../components/bread_crumb";
import { Link } from "react-router-dom";
import folder from "../../assets/folder.png";
import DropdownSelect from "../../components/dropdown_select";
import AddCategory from "../../components/add_category";
import { useSelector } from "react-redux";
import IconButton from "../../components/icon_button";
import { MdOutlineClose, MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import axios from "axios";
import Modal from "../../components/modal.component";

const api = new ApiService();
export default function DuasDir() {
	const [hovered, setHovered] = useState("");
	const [data, setData] = useState([]);
	const [success, setSuccess] = useState({});
	const [deleteOpen, openDelete] = useState(false);
	const [id, setId] = useState(null);
	const user = useSelector((st) => st.user).user;
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await api.getRequest(
				"api/v1/duas/categories?limit=50",
				user.token
			);
			const res = response.data.duaCategories;
			setData(res);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const handleDeleteImg = async () => {
		const res = await axios.delete(
			`${process.env.REACT_APP_API_URL}/api/v1/duas/${id}`,
			{
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
		);
		const data = res.data;
		console.log("DATA");
		delete data.data;
		console.log("DATA DATA", data);
		setSuccess(data);
		openDelete(false);
		fetchData();
	};

	// const data = React.useMemo(() => getDuas(), []);
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Duas", path: "/duas" },
	];
	return (
		<DashboardLayout>
			{" "}
			<div className="flex justify-between items-center mx-4 my-2">
				<Breadcrumb routes={routes} />
				<div className="flex  justify-between justify-items-center">
					{/* <UploadModal /> */}
					<AddCategory refetch={fetchData} />
				</div>
			</div>
			<div className="flex flex-wrap mt-6 mx-8 ">
				{data.map((item, index) => (
					<div
						className="w-1/11 p-4 relative flex flex-col"
						key={index}
						onMouseEnter={() => setHovered(index)}
						onMouseLeave={() => setHovered(index)}>
						<Link
							to={`/dua/${item.title}`}
							state={{ id: item.id, name: item.name }}
							className="" // Add this class for positioning
						>
							<div className=" rounded-md p-1">
								<img src={folder} alt="Folder" className="w-12 mx-auto mb-1" />
								<p className="text-center whitespace-pre-line ">
									{item.title.substring(0, 21)}
								</p>
							</div>
						</Link>{" "}
						{/* Delete and Edit icons */}
						{hovered === index && (
							<div className="absolute top-0  left-0 transition-opacity duration-300 flex items-center justify-center">
								{/* <IconButton
								icon={MdOutlineEdit}
								label="delete"
								onClick={handleDeleteImg}
								color="text-emerald-500"
								size={18}
							/> */}
								<IconButton
									icon={MdOutlineDelete}
									label="delete"
									onClick={() => {
										setId(item.id);
										openDelete(true);
									}}
									color="text-red-500"
									size={18}
								/>
							</div>
						)}
					</div>
				))}
			</div>
			<CategoryModal
				handleSubmit={handleDeleteImg}
				action={"delete"}
				closeModal={() => {
					setId(null);
					openDelete(false);
				}}
				isModalOpen={deleteOpen}
				title={"Delete category"}>
				<p>Are you sure you want to delete?</p>
			</CategoryModal>
		</DashboardLayout>
	);
}
const CategoryModal = ({
	isModalOpen,
	title,
	closeModal,
	children,
	handleSubmit,
	action,
}) => {
	return (
		<>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[30%] ">
						<div className="flex justify-between">
							<h2 className="text-2xl font-bold mb-4">{title}</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={closeModal}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<hr />
						<div className="py-4 px-2">{children}</div>

						<div className="mt-6 flex justify-end">
							<button
								className="bg-gray-300 hover:bg-gray-400 text-white font-bold py-1 px-4 rounded mr-2 uppercase"
								onClick={closeModal}>
								Close
							</button>
							<button
								className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-1 px-4 rounded uppercase"
								onClick={handleSubmit}>
								{action}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
const objects = [
	{ name: "text", instructions: "Step 1 " },
	{ name: "text", instructions: "Step 2" },
	{ name: " ", instructions: "step 2 cont.." },
	{ name: "text", instructions: "Step 4 " },
	{ name: "", instructions: "" },
	{ name: "text", instructions: "Step 6 " },
	{ name: "", instructions: "" },
	{ name: "text", instructions: "Step 7" },
	{ name: "", instructions: "Step 7 cont..." },
];

const newobjects = [
	{ name: "text", instructions: "Step 1 " },
	{ name: "text", instructions: "Step 2 step 2 cont.." },

	{ name: "text", instructions: "Step 4 " },

	{ name: "text", instructions: "Step 6 " },

	{ name: "text", instructions: "Step 7 Step 7 cont..." },
];
