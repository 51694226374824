import React, { useEffect, useState } from "react";
import Post from "./components/Post";
import DashboardLayout from "../../layouts/admin_layout";
import Breadcrumb from "../../../components/bread_crumb";
import { useSelector } from "react-redux";
import axios from "axios";
import PostFormCard from "./components/PostFormCard";

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [isForbidden, setIsForbidden] = useState(false);

  const routes = [
    { name: "Dashboard", path: "/" },
    { name: "Feeds", path: "/posts" },
  ];
  //get feeds
  const user = useSelector((st) => st.user).user;
  useEffect(() => {
    getFeeds();
  }, []);

  const getFeeds = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/feeds`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("RES POSTS", res.data.data.feeds);
      const feeds = res.data.data.feeds;
      console.log("RES POSTS", feeds);
      setPosts(feeds);
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        setIsForbidden(true);
      } else {
        console.log("ERROR", error.response.data.message);
      }
    }
  };
  function fetchPosts() {
    getFeeds();
  }

  return (
    <DashboardLayout>
      <div className="flex justify-between items-center ">
        <Breadcrumb routes={routes} />{" "}
      </div>
      <div className="max-w-2xl mx-auto p-4">
        <PostFormCard onPost={fetchPosts} />
        {posts.map((post) => (
          <Post key={post.id} post={post} />
        ))}
      </div>
    </DashboardLayout>
  );
};

export default Feed;
