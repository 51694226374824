import React, { useEffect, useState } from "react";
import Table, {
	ActionCollumn,
	DuaArabicCell,
	SelectColumnFilter,
} from "../../../components/user_table";
import UploadModal from "../../../../../components/upload_modal";
import ApiService from "../../../../../api/app.api";
import CreateModal from "../../../../../components/create_modal";
import DashboardLayout from "../../../../layouts/admin_layout";
import Breadcrumb from "../../../../../components/bread_crumb";
import { Link, useLocation } from "react-router-dom";
import file from "../../../../../assets/file.png";
import { useSelector } from "react-redux";

const api = new ApiService();
export default function Duas() {
	const [data, setData] = useState([]);
	const [category, setCat] = useState({});
	const location = useLocation();
	const { id, name } = location.state;
	const user = useSelector((st) => st.user).user;
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await api.getRequest(
				`api/v1/duas/categories/duas/${id}`,
				user.token
			);
			const res = response.data.duas;
			setData(res);
			delete response.data.duas;
			setCat(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Arabic",
				accessor: "duaArabic",
				Cell: DuaArabicCell,
			},
			{
				Header: "English",
				accessor: "duaEnglish",
			},
			{
				Header: "Translation",
				accessor: "translation",
			},
			{
				Header: "Type",
				accessor: "duaType",
				Filter: SelectColumnFilter, // new
				filter: "includes",
			},
			{
				Header: "Actions",
				Cell: ActionCollumn,

				// idAccessor: "id",
				// typeAccessor: "duaType",
				// nameAccessor: "duaType",
				// duaArabicAccessor: "duaType",
				// duaEngAccessor: "duaType",
				// translationAccessor: "duaType",
			},
		],
		[]
	);
	// const data = React.useMemo(() => getDuas(), []);
	const sortedObjects = data
		.reduce((accumulator, currentObject) => [...accumulator, currentObject], [])
		.sort((a, b) => {
			const nameA = a.name.toLowerCase();
			const nameB = b.name.toLowerCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Duas", path: "/duas" },
		{ name: `${name}`, path: `/dua/${name}` },
		{
			name: `${category.title}`,
			path: `/dua/${name}/${category.title}`,
		},
	];
	return (
		<DashboardLayout>
			{" "}
			<div className="flex justify-between items-center mx-4 my-2">
				<Breadcrumb routes={routes} />
				<div className="flex  justify-between justify-items-center">
					{/* <UploadModal /> */}
					<CreateModal refetch={fetchData} id={id} user={user} />
				</div>
			</div>
			<div className="flex flex-wrap mt-6 mx-8">
				{sortedObjects.map((item, index) => (
					<div className="w-1/11 p-4" key={index}>
						<Link
							to={`/dua/${name}/${category.title}/${item.id}`}
							state={{ item: item, category: category, parent: name }}>
							<div className="rounded-md p-4">
								<img src={file} alt="Folder" className="w-12 mx-auto mb-2" />
								<p className="text-center whitespace-pre-line ">{item.name}</p>
							</div>
						</Link>
					</div>
				))}
				{/* <Table columns={columns} data={data} /> */}
			</div>
		</DashboardLayout>
	);
}
