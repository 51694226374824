const initialState = {
	user: {},
};

export const authReducer = (state = initialState, action) => {
	console.log("USER DATA", action.data);
	switch (action.type) {
		case "LOGIN_USER":
			return {
				...state,
				user: action.data,
			};
		case "UPDATE_USER":
			return {
				...state,
				user: { ...state.user, ...action.data },
			};
		case "LOG_OUT":
			return {
				...state,
				user: {},
			};

		default:
			return state;
	}
};
