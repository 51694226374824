import { Link } from "react-router-dom";

const Breadcrumb = ({ routes }) => {
	return (
		<nav className="text-sm">
			<ol className="list-none flex">
				{routes.map((route, index) => (
					<li key={route.path} className="flex items-center">
						{index !== 0 && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-3 h-3 text-gray-500 mx-0.4">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M8.25 4.5l7.5 7.5-7.5 7.5"
								/>
							</svg>
						)}
						{route.path ? (
							<Link
								to={route.path}
								className="text-blue-500 hover:text-blue-700">
								{route.name}
							</Link>
						) : (
							<span className="text-gray-700">{route.name}</span>
						)}
					</li>
				))}
			</ol>
		</nav>
	);
};

export default Breadcrumb;
