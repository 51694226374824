import axios from "axios";

class ApiService {
	baseURL = process.env.REACT_APP_API_URL;
	/*
    auth
    */

	async signup(endpoint, body) {
		const url = `${this.baseURL}/${endpoint}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			body: body,
		});
		const data = await response.json();
		console.log("DATA", data);
		return data;
	}

	async signup(endpoint, body) {
		const url = `${this.baseURL}/${endpoint}`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: body,
		});
		const res = await response.json();

		return res;
	}
	//accountverify
	async postRequest(endpoint, body) {
		const url = `${this.baseURL}/${endpoint}`;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
			},
			body: body,
		});
		const res = await response.json();

		return res;
	}
	async postRequestwithBearer(endpoint, body, token) {
		const url = `${this.baseURL}/${endpoint}`;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json; charset=utf-8",
			},
			body: body,
		});
		const res = await response.json();

		return res;
	}
	//put request
	async putRequest(endpoint, body) {
		const url = `${this.baseURL}/${endpoint}`;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
			},
			body: body,
		});
		const res = await response.json();

		return res;
	}
	//delete resource
	async deleteRequest(endpoint) {
		const url = `${this.baseURL}/${endpoint}`;
		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
			},
		});
		const res = await response.json();

		return res;
	}

	async multipartRequest(endpoint, body) {
		console.log("BODY", body);
		const url = `${this.baseURL}/${endpoint}`;
		const response = await axios.post(url, body);
		const res = await response.json();

		return res;
	}
	async postRequestWithToken(endpoint, body, token) {
		const url = `${this.baseURL}/${endpoint}?token=${token}`;
		console.log("URL TOKEN", url);
		const response = await fetch(url, {
			method: "POST",
			body: body,
			headers: {
				"Content-Type": "application/json",
			},
		});
		const res = await response.json();

		return res;
	}
	async getRequest(endpoint, token) {
		const url = `${this.baseURL}/${endpoint}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			// body: body,
		});
		const data = await response.json();
		console.log("DATA", data);
		return data;
	}
}

export default ApiService;
