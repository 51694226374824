import { useState } from "react";
import logo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../api/app.api";
import { loginUser } from "../../store/actions/actions";
import { useNavigate } from "react-router-dom";

const api = new ApiService();
const ChangePassword = () => {
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((st) => st.user).user;
	console.log("USER :::", user);
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!authenticatePassword(password, confirmPassword)) {
			validatePasswords();
			return;
		}
		const body = JSON.stringify({
			password,
			confirmPassword,
		});
		const tokn = user.token;
		console.log("TOKEN", tokn);
		const response = await api.postRequestWithToken(
			"api/v1/auth/change-password",
			body,
			tokn
		);
		if (!response.status) {
			setError(response.message);
			return;
		}
		let token = response.data.token;
		const me = response.data.user;
		const usr = { ...me, token: token, isVerified: true };
		dispatch(loginUser(usr));
		setSuccess(response.message);
		navigate("/");
	};
	function authenticatePassword(password, confirmPassword) {
		if (password === confirmPassword) {
			console.log("PASS", password);
			console.log("2", confirmPassword);
			console.log("MATCH", password === confirmPassword);
			return true;
		} else {
			// Passwords do not match
			return false;
		}
	}
	const validatePasswords = () => {
		if (authenticatePassword(password, confirmPassword)) {
			// Passwords match, perform further actions
			setError("");
			setSuccess("Passwords matched");
		} else {
			{
				setSuccess("");
				setError("Passwords do not match");
			}
		}
	};
	return (
		<div className="min-h-[90vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
					{" "}
					<div className="sm:mx-auto sm:w-full sm:max-w-md mb-10">
						<img
							className="mx-auto h-20 w-auto"
							src={logo}
							alt="Logo"
							width={48}
							height={48}
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Reset your password
						</h2>
					</div>
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="username"
								className="block text-sm font-medium text-gray-700">
								Password
							</label>
							<div className="mt-1">
								<input
									id="username"
									name="username"
									type="password"
									autoComplete="current-password"
									required
									value={password}
									onChange={(event) => setPassword(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="confirmpassword"
								className="block text-sm font-medium text-gray-700">
								Confirm Password
							</label>
							<div className="mt-1">
								<input
									id="confirmpassword"
									name="confirmPassword"
									type="password"
									autoComplete="confirmpassword"
									required
									value={confirmPassword}
									onChange={(event) => {
										setConfirmPassword(event.target.value);
									}}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
								/>
							</div>
						</div>
						{error && (
							<div className="mt-4">
								<div
									className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{error}</span>
								</div>
							</div>
						)}
						{success && (
							<div className="mt-4">
								<div
									className="bg-emerald-100 border border-emerald-400 text-emerald-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{success}</span>
								</div>
							</div>
						)}
						<div>
							<button
								type="submit"
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Change Password
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
