import React from "react";
import { Outlet } from "react-router-dom";
import DashboardLayout from "./admin_layout";

export default function ChatLayout() {
	return (
		<DashboardLayout>
			<div>
				<Outlet />
			</div>
		</DashboardLayout>
	);
}
