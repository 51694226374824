import React, { useState } from "react";

const DropdownSelect = ({ selectedOption, handleOptionChange, name }) => {
	return (
		<div className="flex items-center space-x-2">
			<label htmlFor="dropdown" className="text-sm font-medium text-gray-700">
				Select an option:
			</label>
			<select
				id="dropdown"
				name={name}
				value={selectedOption}
				onChange={handleOptionChange}
				className="appearance-none block w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
				<option value="">Select...</option>
				<option value="DUA">DUA</option>
				<option value="TAWASSOL">TAWASSOL</option>
			</select>
		</div>
	);
};

export default DropdownSelect;
