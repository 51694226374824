import { useEffect, useRef, useState } from "react";
import { AiOutlineComment, AiOutlineMenuFold, AiOutlineSend } from "react-icons/ai";
import { FaCogs, FaPray, FaRegImages, FaUsers } from "react-icons/fa";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";
import DashboardIcon from "./icons/dashboard";
import SideBarItem from "./side_bar_item";
const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  //expand widget
  const ExpandWidget = () => {
    return (
      <svg
        className={`w-4  ml-auto transform ${
          settingsOpen ? "rotate-0" : "-rotate-90"
        } transition-transform`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    );
  };
  console.log("SIDE SIDE BAR OPEN", sidebarOpen);
  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-[9999] flex h-screen w-72 flex-col overflow-y-hidden duration-300 ease-linear dark:bg-white lg:static lg:translate-x-0  shadow-xl bg-whiten ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="relative">
        <NavLink to="/">
          <div className="flex justify-center items-center mx-auto">
            <img
              src={Logo}
              alt="Logo"
              className="h-16 w-16  md:h-16 md:w-16 p-2"
            />
            <h3 className="font-bold font-serif text-4xl"> Shifa</h3>
          </div>
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden absolute top-0 right-0 bg-gray"
        >
          <AiOutlineMenuFold />
        </button>
      </div>
      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className=" py-4 px-4 lg:px-6">
          <ul>
            <SideBarItem
              href={"/"}
              icon={<DashboardIcon />}
              name={"Dashboard"}
            />
            <SideBarItem
              href={"/users"}
              icon={<FaUsers />}
              name={"Users"}
              isActive={pathname === "/users"}
            />{" "}
            <SideBarItem href={"/duas"} icon={<FaPray />} name={"Duas"} />
            <SideBarItem
              href={"/requests"}
              icon={<RiMailSendLine />}
              name={"Requests"}
            />
            <SideBarItem
              href={"/invites"}
              icon={<AiOutlineSend />}
              name={"Invites"}
            />
            <SideBarItem href={"/posts"} icon={<AiOutlineComment />} name={"Posts"} />
            <SideBarItem
              href={"/well-cards"}
              icon={<FaRegImages />}
              name={"Well Cards"}
            />
            <SideBarItem
              href={"#"}
              icon={<FaCogs />}
              name={"Settings"}
              onClick={toggleSettings}
              nestedIcon={<ExpandWidget />}
            >
              {settingsOpen && (
                <>
                  <div className="pl-8">
                    <SideBarItem
                      href={"/settings/administrators"}
                      icon={<MdOutlineAdminPanelSettings />}
                      name={"Administartors"}
                    />
                  </div>
                </>
              )}
            </SideBarItem>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
