import axios from "axios";
import { useState } from "react";
import ClickOutHandler from "react-clickout-handler";
import { FaRegComments } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import Avatar from "./Avatar";
import Card from "./Card";
import InitialsAvatar from "./InitialsAvatar";

export default function PostCard({ post }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [likes, setLikes] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const profile = useSelector((st) => st.user).user;
  const [isForbidden, setIsForbidden] = useState(false);

  function openDropdown(e) {
    e.stopPropagation();
    setDropdownOpen(true);
  }
  function handleClickOutsideDropdown(e) {
    e.stopPropagation();
    setDropdownOpen(false);
  }

  const isLikedByMe = !!likes.find((like) => like.user_id === profile?.id);

  async function toggleLike() {

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/feeds/${post.id}/likes`,
        {},
        {
          headers: {
            Authorization: `Bearer ${profile.token}`,
          },
        }
      );
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        setIsForbidden(true);
      } else {
        console.log("ERROR", error.response.data.message);
      }
    }
  }

  async function togglePray() {

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/feeds/${post.id}/prayers`,
        {},
        {
          headers: {
            Authorization: `Bearer ${profile.token}`,
          },
        }
      );
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        setIsForbidden(true);
      } else {
        console.log("ERROR", error.response.data.message);
      }
    }
  }
  async function toggleThanks() {

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/feeds/${post.id}/thanks`,
        {},
        {
          headers: {
            Authorization: `Bearer ${profile.token}`,
          },
        }
      );
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        setIsForbidden(true);
      } else {
        console.log("ERROR", error.response.data.message);
      }
    }
  }
  async function toggleSorry() {

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/feeds/${post.id}/sorry`,
        {},
        {
          headers: {
            Authorization: `Bearer ${profile.token}`,
          },
        }
      );
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        setIsForbidden(true);
      } else {
        console.log("ERROR", error.response.data.message);
      }
    }
  }

  async function postComment(ev) {
    ev.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/feeds/${post.id}/comments`,
        {
          content: commentText,
        },
        {
          headers: {
            Authorization: `Bearer ${profile.token}`,
          },
        }
      );
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        setIsForbidden(true);
      } else {
        console.log("ERROR", error.response.data.message);
      }
    }
  }
  const author = post.user.firstname + " " + post.user.lastname;

  return (
    <Card>
      <div className="flex gap-3">
        <div>
          <Link to={"/profile"}>
            <span className="cursor-pointer">
              {post.user.imageUrl ? (
                <Avatar
                  url={`https://shifa-bckt.s3.amazonaws.com/${post.user.imageUrl}`}
                />
              ) : (
                <InitialsAvatar
                  firstname={post.user.firstname}
                  lastname={post.user.lastname}
                />
              )}
            </span>
          </Link>
        </div>
        <div className="grow">
          <p>
            <Link to={"/profile/" + post.user.id}>
              <span className="mr-1 font-semibold cursor-pointer hover:underline">
                {author}
              </span>
            </Link>
            shared a post
          </p>
          <p className="text-gray-500 text-sm">
            <ReactTimeAgo date={new Date(post.createdAt).getTime()} />
          </p>
        </div>
        <div className="relative">
          <button className="text-gray-400" onClick={openDropdown}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </button>
          {dropdownOpen && (
            <div className="bg-red w-5 h-5 absolute top-0"></div>
          )}
          <ClickOutHandler onClickOut={handleClickOutsideDropdown}>
            <div className="relative ">
              {dropdownOpen && (
                <div className="absolute -right-6 bg-white shadow-md shadow-gray-300 p-3 rounded-sm border border-gray-100 w-52 z-[99]">
                  <a
                    href=""
                    className="flex gap-3 py-2 my-2 hover:bg-socialBlue hover:text-red-500 -mx-4 px-4 rounded-md transition-all hover:scale-105 hover:shadow-lg shadow-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                    Delete
                  </a>
                  <a
                    href=""
                    className="flex gap-3 py-2 my-2 hover:bg-socialBlue hover:text-amber-500 -mx-4 px-4 rounded-md transition-all hover:scale-105 hover:shadow-lg shadow-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                    Report
                  </a>
                </div>
              )}
            </div>
          </ClickOutHandler>
        </div>
      </div>
      <div>
        <p className="my-3 text-sm">{post.content}</p>
        {/* {photos?.length > 0 && (
          <div className="flex gap-4">
            {photos.map((photo) => (
              <div key={photo} className="">
                <img src={photo} className="rounded-md" alt="" />
              </div>
            ))}
          </div>
        )} */}
      </div>
      <div className="mt-5 flex gap-8">
        <button className="flex gap-2 items-center" onClick={toggleLike}>
          <div role="img" aria-label="like">
            👍
          </div>{" "}
          {post.likes?.length}
        </button>
        <button className="flex gap-2 items-center" onClick={togglePray}>
          <div role="img" aria-label="pray">
            🤲🏻
          </div>{" "}
          {post?.prayers?.length}
        </button>
        <button className="flex gap-2 items-center" onClick={toggleThanks}>
          <div role="img" aria-label="pray">
            🙏
          </div>{" "}
          {post?.thanks?.length}
        </button>
        <button className="flex gap-2 items-center" onClick={toggleSorry}>
          <div role="img" aria-label="pray">
            😓
          </div>{" "}
          {post?.sorry?.length}
        </button>
        <button className="flex gap-2 items-center">
          <FaRegComments />
          {post?.comments.length}
        </button>
      </div>
      <div className="flex mt-4 gap-3">
        <div>
          {profile.imageUrl ? (
            <Avatar
              url={`https://shifa-bckt.s3.amazonaws.com/${profile.imageUrl}`}
            />
          ) : (
            <InitialsAvatar
              firstname={profile.firstname}
              lastname={profile.lastname}
            />
          )}
        </div>
        <div className="border grow rounded-full relative ">
          <form onSubmit={postComment}>
            <input
              value={commentText}
              onChange={(ev) => setCommentText(ev.target.value)}
              className="block w-full p-3 px-4 overflow-hidden h-12 rounded-full"
              placeholder="Leave a comment"
            />
          </form>
          <button className="absolute top-3 right-3 text-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div>
        {post.comments.length > 0 &&
          post.comments.map((comment) => (
            <div key={comment.id} className="mt-2 flex gap-2 items-center">
              {comment?.user?.imageUrl ? (
                <Avatar
                  url={`https://shifa-bckt.s3.amazonaws.com/${comment?.user?.imageUrl}`}
                />
              ) : (
                <InitialsAvatar
                  firstname={comment?.user?.firstname}
                  lastname={comment?.user?.lastname}
                />
              )}

              <div className="bg-gray-200 py-2 px-4 rounded-3xl">
                <div>
                  <Link to={"/profile/" + comment?.user?.id}>
                    <span className="hover:underline font-semibold text-sm mr-1">
                     {`${comment?.user?.firstname} ${comment?.user?.lastname}`}
                    </span>
                  </Link>
                  <span className="text-sm text-gray-400">
                    <ReactTimeAgo
                      timeStyle={"twitter"}
                      date={new Date(comment.createdAt).getTime()}
                    />
                  </span>
                </div>
                <p className="text-sm">{comment.content}</p>
              </div>
            </div>
          ))}
      </div>
    </Card>
  );
}
