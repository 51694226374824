"use client";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import ApiService from "/src/api/app.api";
import logo from "./assets/logo.png";
import { loginUser } from "./store/actions/actions";
import ApiService from "./api/app.api";

const api = new ApiService();
const DeleteAccount = () => {
  const [error, setError] = useState("");
  const [email, setUsername] = useState("");
  const [deleted, setDeleted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = JSON.stringify({
      email,
    });
    const response = await api.postRequest("api/v1/auth/delete_account", body);
    if (!response.status) {
      setError(response.message);
      return;
    }

    setDeleted(true);
  };

  return (
    <div className="min-h-[90vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
          {" "}
          <div className="sm:mx-auto sm:w-full sm:max-w-md mb-10">
            <img
              className="mx-auto h-20 w-auto"
              src={logo}
              alt="Logo"
              width={48}
              height={48}
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-red-500">
              Delete your account
            </h2>
          </div>
          {deleted ? (
            <>
             <div className="mb-6 py-4 px-2 rounded bg-red-300 text-center">
                <p className="font-bold ">
                  Your account has been  successfully deleted!
                </p>
                <small className="font-bold ">You may now exit the browser</small>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="mb-6 py-4 px-2 rounded bg-red-300">
                <p className="font-bold ">
                  Please Keep in mind, this process cannot be reversed!
                </p>
              </div>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(event) => setUsername(event.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                </div>
                {error && (
							<div className="mt-4">
								<div
									className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{error}</span>
								</div>
							</div>
						)}
                <div>
                  <button
                    type="submit"
                    className="bg-red-500 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Delete Account
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
