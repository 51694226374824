import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../layouts/admin_layout";
import Table, {
  AvatarCell,
  CountryColum,
  SelectColumnFilter,
  StatusPill,
  UserActionCollumn,
} from "./components/user_table";
import Modal from "../../components/modal.component";
import Breadcrumb from "../../components/bread_crumb";
import CountrySelector from "../../components/country_selector";
import InputField from "../../components/input_field";
import { COUNTRIES } from "../lib/countries";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NoPermissionWidget from "./components/NoPermissionWidget";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const user = useSelector((st) => st.user).user;
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/users/users`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("RES USERS", res.data.data.users);
      const data = res.data.data.users;
      console.log("RES USERS", data);
      setUsers(data);
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        setIsForbidden(true);
      } else {
        console.log("ERROR", error.response.data.message);
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Full Names",
        accessor: "firstname",
        Cell: AvatarCell,
        imgAccessor: "imageUrl",
        emailAccessor: "email",
        lastnameAccessor: "lastname",
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: CountryColum,
      },
      {
        Header: "Status",
        accessor: "isVerified",
        Cell: StatusPill,
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Role",
        accessor: "role.name",
        Filter: SelectColumnFilter, // new
        filter: "includes",
      },
      {
        Header: "Actions",
        Cell: UserActionCollumn,
      },
    ],
    []
  );

  // const data = React.useMemo(async () => getUsers(), []);
  const routes = [
    { name: "Dashboard", path: "/" },
    { name: "Users", path: "/users" },
  ];

  return (
    <DashboardLayout>
      {" "}
      <div className="flex justify-between items-center mx-8 mt-2">
        <Breadcrumb routes={routes} />
        {isForbidden ? (
          <></>
        ) : (
          <div className="flex  justify-between justify-items-center">
            <CreateUser refetch={getUsers} />
          </div>
        )}
      </div>{" "}
      {isForbidden ? (
        <NoPermissionWidget />
      ) : (
        <div className="mt-6 mx-8">
          <Table columns={columns} data={users} />
        </div>
      )}
    </DashboardLayout>
  );
}
const CreateUser = ({ refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [country, setCountry] = useState("BE");
  const [isOpen, setIsOpen] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDOB] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const user = useSelector((st) => st.user).user;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
    setDOB("");
  };
  const handleCreateUser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/users/create-user`,
        {
          email: email,
          firstname: firstname,
          lastname: lastname,
          password: password,
          country: country,
          dateOfBirth: dob,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (!response.status) {
        setError(response.message);
        return;
      }

      console.log("USER", response.data.data);
      closeModal();
      setFirstname("");
      setLastname("");
      setEmail("");
      setPassword("");
      setDOB("");
      refetch();
    } catch (error) {
      console.error("ER", error.response.data.statusCode);
      if (error.response.data.statusCode === 401) {
        setError(
          "You are not authorized to perfom this action. Kindly contact the admin."
        );
      } else {
        setError("Something went wrong. Please try again later.");
      }
    }
  };
  return (
    <Modal
      handleSubmit={handleCreateUser}
      closeModal={closeModal}
      isModalOpen={isModalOpen}
      openModal={openModal}
      action={"Add user"}
      label={"Create User"}
      title={"Create User"}
    >
      <InputField
        name={"firstname"}
        autoComplete={"firstname"}
        handleOnChange={(e) => setFirstname(e.target.value)}
        label={"First Name"}
        required={true}
        value={firstname}
        placeholder={"First Name"}
        type="text"
      />
      <InputField
        name={"lastname"}
        autoComplete={"lastname"}
        handleOnChange={(e) => setLastname(e.target.value)}
        label={"Last Name"}
        required={true}
        value={lastname}
        placeholder={"Last Name"}
        type="text"
      />
      <InputField
        name={"email"}
        autoComplete={"Email"}
        handleOnChange={(e) => setEmail(e.target.value)}
        label={"Email"}
        required={true}
        value={email}
        placeholder={"Email"}
        type="email"
      />
      <InputField
        name={"password"}
        autoComplete={"password"}
        handleOnChange={(e) => setPassword(e.target.value)}
        label={"Password"}
        required={true}
        value={password}
        placeholder={"Password"}
        type="password"
      />
      <InputField
        name={"dob"}
        autoComplete={"dob"}
        handleOnChange={(e) => setDOB(e.target.value)}
        label={"Date of Birth"}
        required={true}
        value={dob}
        placeholder={"Date of birth"}
        type="date"
      />
      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          Country
        </label>
        <div className="mt-1">
          <CountrySelector
            id={"country-selector"}
            open={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
            onChange={setCountry}
            selectedValue={COUNTRIES.find((option) => option.value === country)}
          />
        </div>
      </div>
      {error && (
        <div className="mt-4">
          <div
            className=" flex justify-between bg-meta-7 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
            <span className="hover:cursor-pointer" onClick={() => setError("")}>
              X
            </span>
          </div>
        </div>
      )}
    </Modal>
  );
};
