import React from "react";
import DuaCard from "./dua_card";
import { useLocation } from "react-router-dom";
import DashboardLayout from "../../../../layouts/admin_layout";
import Breadcrumb from "../../../../../components/bread_crumb";

export default function Dua() {
	const location = useLocation();
	const { item, category, parent } = location.state;
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Duas", path: "/duas" },
		{ name: `${parent}`, path: `/dua/${parent}` },
		{ name: `${category.title}`, path: `/dua/${parent}/${category.title}` },
		{ name: `${item.name}` },
	];
	return (
		<DashboardLayout>
			<div className="mx-4 my-2">
				<Breadcrumb routes={routes} />
			</div>

			<div className="mt-6 mx-8">
				<DuaCard dua={item} />
			</div>
		</DashboardLayout>
	);
}
