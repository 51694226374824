import React, { useEffect, useState } from "react";
import Table, {
	ActionCollumn,
	DuaArabicCell,
	InviteActionCollumn,
	SelectColumnFilter,
} from "./components/user_table";
import UploadModal from "../../components/upload_modal";
import ApiService from "../../api/app.api";
import CreateModal from "../../components/create_modal";
import Modal from "../../components/modal.component";
import InputField from "../../components/input_field";
import DashboardLayout from "../layouts/admin_layout";
import Breadcrumb from "../../components/bread_crumb";
import FolderScreen from "./components/invite_folders";
import { useSelector } from "react-redux";
import axios from "axios";

const api = new ApiService();
export default function InviteFolders() {
	const [fullnames, setNames] = useState("");
	const [isender, setSender] = useState("");
	const [itime, setTime] = useState("");
	const [idate, setDate] = useState("");
	const [phone, setPhone] = useState("");
	const [ilocation, setLocation] = useState("");
	const [data, setData] = useState([]);
	const [selectedOption, setInviteType] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const user = useSelector((st) => st.user).user;
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		// fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/v1/send-invitations/invites?limit=50`,
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			console.log("INVITES", response);
			const res = response.data.sendInvitations;
			setData(res);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Reciever",
				accessor: "name",
			},
			{
				Header: "Sender",
				accessor: "sender",
			},
			{
				Header: "Time",
				accessor: "time",
			},
			{
				Header: "Date",
				accessor: "date",
				Filter: SelectColumnFilter, // new
				filter: "includes",
			},
			{
				Header: "Location",
				accessor: "location",
			},
			{
				Header: "Phonenumber",
				accessor: "phonenumber",
				// Filter: SelectColumnFilter, // new
				// filter: "includes",
			},
			{
				Header: "Type",
				accessor: "type",
				Filter: SelectColumnFilter, // new
				filter: "includes",
			},
			{
				Header: "Actions",
				Cell: InviteActionCollumn,
			},
		],
		[]
	);
	// const data = React.useMemo(() => getDuas(), []);
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Invites", path: "/invites" },
	];
	const handleCreate = async () => {
		const name = fullnames;
		const sender = isender;
		const time = itime;
		const date = idate;
		const location = ilocation;
		const phonenumber = phone;
		const type = selectedOption;
		const body = JSON.stringify({
			name,
			sender,
			time,
			date,
			location,
			phonenumber,
			type,
		});
		const data = await api.postRequest(`api/v1/send-invitations/invite`, body);
		console.log("DATA", data);
		closeModal();
		fetchData();
	};
	return (
		<DashboardLayout>
			<div className="flex justify-between items-center mr-16 my-2">
				<Breadcrumb routes={routes} />

				<div className="flex  justify-between justify-items-center">
					{/* <UploadModal /> */}
					{/* <Modal
						handleSubmit={handleCreate}
						closeModal={closeModal}
						isModalOpen={isModalOpen}
						openModal={openModal}
						label={"add folder"}
						title={"add folder"}
						action={"Add folder"}>
						<InputField
							name={"name"}
							autoComplete={"name"}
							handleOnChange={(e) => setNames(e.target.value)}
							label={"Full Names (receiver)"}
							required={true}
							value={fullnames}
							placeholder={"Full Names"}
							type="text"
						/>
						<InputField
							name={"name"}
							autoComplete={"name"}
							handleOnChange={(e) => setSender(e.target.value)}
							label={"sender (full names)"}
							required={true}
							value={isender}
							placeholder={"sender Names"}
							type="text"
						/>
						<InputField
							name={"name"}
							autoComplete={"name"}
							handleOnChange={(e) => setTime(e.target.value)}
							label={"time"}
							required={true}
							value={itime}
							placeholder={"time"}
							type="time"
						/>
						<InputField
							name={"name"}
							autoComplete={"name"}
							handleOnChange={(e) => setDate(e.target.value)}
							label={"date"}
							required={true}
							value={idate}
							placeholder={"date"}
							type="date"
						/>
						<InputField
							name={"name"}
							autoComplete={"name"}
							handleOnChange={(e) => setPhone(e.target.value)}
							label={"Phone number"}
							required={true}
							value={phone}
							placeholder={"phone number"}
							type="text"
						/>
						<InputField
							name={"name"}
							autoComplete={"name"}
							handleOnChange={(e) => setLocation(e.target.value)}
							label={"Location"}
							required={false}
							value={ilocation}
							placeholder={"Location"}
							type="text"
						/>
						<div className="flex items-center space-x-2">
							<label
								htmlFor="dropdown"
								className="text-sm font-medium text-gray-700">
								Select an option:
							</label>
							<select
								id="dropdown"
								name="type"
								value={selectedOption}
								onChange={(e) => setInviteType(e.target.value)}
								className="appearance-none block w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
								<option value="">Select...</option>
								<option value="VIRTUAL">VIRTUAL</option>
								<option value="INPERSON">INPERSON</option>
								<option value="BOTH">BOTH</option>
							</select>
						</div>
					</Modal> */}
				</div>
			</div>
			<div className="mt-6 mx-8">
				<FolderScreen />{" "}
			</div>
		</DashboardLayout>
	);
}
