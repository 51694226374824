import axios from "axios";
import { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";

const CardFour = ({ user }) => {
	const [stats, setStats] = useState({
		totalCount: 0,
		verifiedCount: 0,
		unverifiedCount: 0,
	});

	useEffect(() => {
		// Fetch user statistics from the server
		fetchUserStats();
	}, []);

	const fetchUserStats = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/v1/users/user-stats`,
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			const data = await response.data.data;
			console.log("USERSTATS", data);
			setStats(data);
		} catch (error) {
			console.error("Failed to fetch user statistics:", error);
		}
	};
	return (
		<div className="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
			<div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
				<FaUsers className="text-primary" />
			</div>

			<div className="mt-4 flex items-end justify-between">
				<div>
					<h4 className="text-title-md font-bold text-black dark:text-white">
						{stats.totalCount}
					</h4>
					<span className="text-sm font-medium">Total Users</span>
				</div>

				<span className="flex items-center gap-1 text-sm font-medium text-meta-5">
					{/* 0.95%
					<svg
						className="fill-meta-5"
						width="10"
						height="11"
						viewBox="0 0 10 11"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
							fill=""
						/>
					</svg> */}
				</span>
			</div>
		</div>
	);
};

export default CardFour;
