import { useState } from "react";
import logo from "../../assets/logo.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiService from "../../api/app.api";
import { loginUser } from "../../store/actions/actions";

const api = new ApiService();
const VerifyEmail = () => {
	const [email, setUsername] = useState("");
	const [success, setSuccess] = useState("");
	const [error, setError] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleSubmit = async (event) => {
		event.preventDefault();
		const body = JSON.stringify({
			email,
		});
		const response = await api.postRequest("api/v1/auth/forgot-password", body);
		if (!response.status) {
			setError(response.message);
			return;
		} else {
			let token = response.data.token;
			const me = response.data.user;
			const usr = { ...me, token: token, isVerified: true };
			dispatch(loginUser(usr));
			setSuccess(response.message);
			console.log("RESET PASSWORD", response);
			navigate("/confirm-otp", { state: { changePassword: true } });
		}
	};

	return (
		<div className="min-h-[90vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
					{" "}
					<div className="sm:mx-auto sm:w-full sm:max-w-md mb-10">
						<img
							className="mx-auto h-20 w-auto"
							src={logo}
							alt="Logo"
							width={50}
							height={50}
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Enter your email
						</h2>
					</div>
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700">
								Email
							</label>
							<div className="mt-1">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="current-email"
									required
									value={email}
									onChange={(event) => setUsername(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
								/>
							</div>
						</div>
						{error && (
							<div className="mt-4">
								<div
									className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{error}</span>
								</div>
							</div>
						)}
						{success && (
							<div className="mt-4">
								<div
									className="bg-emerald-100 border border-emerald-400 text-emerald-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{success}</span>
								</div>
							</div>
						)}

						<div>
							<button
								type="submit"
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-whiten bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Verify Email
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default VerifyEmail;
