import React from "react";

const PrivacyPolicy = () => {
	return (
		<div className="bg-gray-100 min-h-screen flex justify-center items-center">
			<div className="max-w-5xl p-6 bg-white rounded-lg shadow-md">
				<h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
				<p className="mb-6">
					At Shifa786, safeguarding your privacy is a paramount concern for us.
					We want to provide you with a clear understanding of how we handle
					your personal information. Please take a moment to review our Privacy
					Policy:
				</p>
				<ol className="list-decimal pl-6 mb-6">
					<li className="mb-2">
						<strong>Information Collection:</strong>
						<br />- To enhance your experience with the app, we collect certain
						personal information from you during the registration process,
						including your name, email, phone number, and location.
					</li>
					<li className="mb-2">
						<strong>Information Usage:</strong>
						<br />- Your provided information serves multiple purposes,
						including facilitating app services, responding to your requests,
						and refining our overall offerings.
					</li>
					<li className="mb-2">
						<strong>Information Sharing:</strong>
						<br />- We may collaborate with trusted service providers who assist
						us in maintaining seamless app operations. Rest assured, your
						information is shared with them solely for this purpose.
					</li>
					<li className="mb-2">
						<strong>Ensuring Security:</strong>
						<br />- We are committed to ensuring the security of your
						information and have implemented measures to safeguard it. However,
						it's important to note that while we take every precaution,
						transmitting data over the internet is never entirely risk-free.
					</li>
					<li className="mb-2">
						<strong>Protecting Children's Privacy:</strong>
						<br />- Shifa786 is designed for individuals aged 14 and above. We
						do not knowingly collect personal information from children under
						this age.
					</li>
					<li className="mb-2">
						<strong>Empowering Your Choices:</strong>
						<br />- We empower you with the ability to manage your information.
						You can access, modify, or delete your details by editing your
						profile settings within the app.
					</li>
					<li>
						<strong>Reach Out to Us:</strong>
						<br />- Should you have any questions, concerns, or clarifications
						regarding your privacy, feel free to contact our privacy team at{" "}
						<a href="mailto:shifa786app@yahoo.com" className="text-blue-500">
							shifa786app@yahoo.com
						</a>
						.
					</li>
				</ol>
				<p className="mb-4">
					Our commitment to respecting your privacy is unwavering. Below is a
					more detailed outline of our Privacy Policy:
				</p>
				{/* Repeat the content for each section here */}
				<p className="mb-4">
					Thank you for entrusting us with your information.
				</p>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
