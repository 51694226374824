const IconButton = ({ icon: Icon, label, onClick, color, size }) => {
	return (
		<button
			className="flex items-center space-x-2 bg-gray rounded-full px-2 py-2 focus:outline-none "
			onClick={onClick}>
			<Icon className={"text-lg " + color} size={size} />
			{/* <span className="text-gray-600">{label}</span> */}
		</button>
	);
};
export default IconButton;
