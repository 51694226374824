import { useState } from "react";
import { FaEye } from "react-icons/fa";
import React from "react";
import IconButton from "../../../components/icon_button";
import { MdOutlineClose } from "react-icons/md";

export default function ViewInviteModal({ invite }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	return (
		<div>
			<IconButton
				icon={FaEye}
				label="view"
				onClick={() => openModal()}
				color="text-emerald-500"
			/>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[50%] h-auto">
						<div className="flex justify-between">
							<h2 className="text-xl mb-4 text-center font-semibold">
								Invite information
							</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={() => {
									closeModal();
								}}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<div className="py-4 px-2">
							<div className="mt-4">
								<hr className="my-4" />
								<p className="py-2 px-2">
									{" "}
									Sender: <span className="mx-4">{invite.sender}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Inviting: <span className="mx-4">{invite.name}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Invited on:<span className="mx-4">{invite.date}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Time:<span className="mx-4">{invite.time}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Location: <span className="mx-4">{invite.location}</span>{" "}
								</p>

								<p className="py-2 px-2">
									{" "}
									Type:<span className="mx-4">{invite.type}</span>{" "}
								</p>
								<p className="py-2 px-2">
									{" "}
									Sent on: <span className="mx-4">{invite.createdAt}</span>{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
