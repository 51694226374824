import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const RichTextEditor = ({ editorContent, handleChangeArabic, title }) => {
	return (
		<div className="py-2">
			<h1 className="mb-4">{title}</h1>
			<div className="w-full">
				<ReactQuill
					dir="rtl"
					className="h-auto rounded-md"
					theme="snow"
					placeholder="Type here.."
					value={editorContent}
					onChange={handleChangeArabic}
					modules={{
						toolbar: [
							[{ header: [1, 2, 3, 4, false] }],
							["bold", "italic", "underline", "strike"],
							[{ list: "ordered" }, { list: "bullet" }],
							["clean"],
						],
					}}
				/>
			</div>
		</div>
	);
};

export default RichTextEditor;
