import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../../../api/app.api";
import Breadcrumb from "../../../../components/bread_crumb";
import DashboardLayout from "../../../layouts/admin_layout";
import Table, {
	AvatarCell,
	DateTimeCell,
	InviteActionCollumn,
	SelectColumnFilter,
} from "../../components/user_table";

const api = new ApiService();
export default function DuaEShifa() {
	const [fullnames, setNames] = useState("");
	const [isender, setSender] = useState("");
	const [itime, setTime] = useState("");
	const [idate, setDate] = useState("");
	const [phone, setPhone] = useState("");
	const [ilocation, setLocation] = useState("");
	const [data, setData] = useState([]);
	const [selectedOption, setInviteType] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const user = useSelector((st) => st.user).user;
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await api.getRequest(
				"api/v1/send-requests/dua-e-shifa?limit=20",
				user.token
			);
			console.log("INVITES", response);
			const res = response.data.data;
			setData(res);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Sender",
				accessor: "sender.firstname",

				Cell: AvatarCell,
				imgAccessor: "sender.imageUrl",
				emailAccessor: "sender.email",
				lastnameAccessor: "sender.lastname",
			},
			{
				Header: "Reciever",
				accessor: "receiver",
			},

			{
				Header: "Date",
				accessor: "createdAt",
				Cell: DateTimeCell,
				Filter: SelectColumnFilter, // new
				filter: "includes",
			},
		],
		[]
	);
	// const data = React.useMemo(() => getDuas(), []);
	const handleCreate = async () => {
		const name = fullnames;
		const sender = isender;
		const time = itime;
		const date = idate;
		const location = ilocation;
		const phonenumber = phone;
		const type = selectedOption;
		const body = JSON.stringify({
			name,
			sender,
			time,
			date,
			location,
			phonenumber,
			type,
		});
		const data = await api.postRequest(`api/v1/send-invitations/invite`, body);
		console.log("DATA", data);
		closeModal();
		fetchData();
	};
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Requests", path: "/requests" },
		,
	];
	return <Table columns={columns} data={data} />;
}
