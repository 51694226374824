import { useState } from "react";
import logo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../api/app.api";
import { loginUser } from "../../store/actions/actions";

const api = new ApiService();
const ConfirmOTP = () => {
	const [otp, setOTP] = useState();
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const user = useSelector((st) => st.user);
	console.log("USER :::", user.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const changePassword = location.state.changePassword;
	console.log("CHANGE PASS", changePassword);
	const handleSubmit = async (event) => {
		event.preventDefault();
		let email = user.user.email;
		let code = Number.parseInt(otp);
		console.log("DATA", email, code);
		const body = JSON.stringify({
			email,
			code,
		});
		if (changePassword) {
			const response = await api.signup(
				"api/v1/auth/forgot-password-verify",
				body
			);
			if (!response.status) {
				setError(response.message);
				return;
			}

			let token = response.data.token;
			const me = response.data.user;
			const usr = { ...me, token: token, isVerified: true };
			dispatch(loginUser(usr));
			setSuccess(response.message);
			console.log("PASSWORD CHANGE", response);

			navigate("/reset-password");
		} else {
			const response = await api.signup("api/v1/auth/account-verify", body);
			if (!response.status) {
				setError(response.message);
				return;
			}
			let token = response.data.token;
			const me = response.data.user;
			const usr = { ...me, token: token, isVerified: true };
			dispatch(loginUser(usr));
			setSuccess(response.message);
			navigate("/");
		}
	};
	const resendCode = async (event) => {
		event.preventDefault();
		let email = user.user.email;

		console.log("DATA", email);
		const body = JSON.stringify({
			email,
		});
		const response = await api.signup(
			"api/v1/auth/resend-account-verify",
			body
		);

		if (!response.status) {
			setError(response.message);
			return;
		}
		setSuccess(response.message);
		console.log("USER", response);
		// dispatch(loginUser(response.data));
		// navigate("/");
	};

	return (
		<div className="min-h-[90vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
					{" "}
					<div className="sm:mx-auto sm:w-full sm:max-w-md mb-10">
						<img
							className="mx-auto h-12 w-auto"
							src={logo}
							alt="Logo"
							width={48}
							height={48}
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Verify your Email
						</h2>
					</div>
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="username"
								className="block text-sm font-medium text-gray-700">
								OTP-CODE
							</label>
							<div className="mt-1">
								<input
									id="username"
									name="username"
									type="text"
									autoComplete="current-email"
									required
									value={otp}
									placeholder="Enter OTP Code sent to you"
									onChange={(event) => setOTP(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
								/>
							</div>
						</div>
						<div className="flex items-center justify-between">
							<div>
								<p>Didn't receive OTP or expired code?</p>
							</div>

							<div className="text-sm">
								<a onClick={resendCode} className="font-medium text-primary cursor-pointer text-emerald-700">
									resend OTP
								</a>
							</div>
						</div>
						{error && (
							<div className="mt-4">
								<div
									className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{error}</span>
								</div>
							</div>
						)}
						{success && (
							<div className="mt-4">
								<div
									className="bg-emerald-100 border border-emerald-400 text-emerald-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{success}</span>
								</div>
							</div>
						)}
						<div>
							<button
								type="submit"
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								SEND OTP
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ConfirmOTP;
