import React, { useEffect, useState } from "react";
import ApiService from "../../../api/app.api";
import Breadcrumb from "../../../components/bread_crumb";
import InputField from "../../../components/input_field";
import Modal from "../../../components/modal.component";
import DashboardLayout from "../../layouts/admin_layout";
import Table, {
	InviteActionCollumn,
	SelectColumnFilter,
} from "../components/user_table";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import KhatamAlQuranDash from "./pages/khata_al_quran";
import KhatamAlQuran from "../khatam_al_quran";
import SuratRequests from "./pages/surat_al_fatiha";
import SalatulRequests from "./pages/salatul-wahshat";
import UserWellCards from "./pages/well_cards";
import DuaEShifa from "./pages/dua_e_shifa";

const api = new ApiService();
export default function Requests() {
	const { id } = useParams();

	const user = useSelector((st) => st.user).user;

	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Requests", path: "/requests" },
		{ name: `${id}`, path: `/requests/${id}` },
		,
	];
	console.log("PAGEID", id);
	return (
		<DashboardLayout>
			<div className="flex justify-between items-center mr-16 my-2">
				<Breadcrumb routes={routes} />
				<div></div>
			</div>
			<div className="mt-6 mx-8">
				{/* <Table columns={columns} data={data} /> */}
				{id == 1 && <DuaEShifa />}
				{id == 2 && <UserWellCards />}
				{id == 3 && <SalatulRequests />}
				{id == 4 && <SuratRequests />}
				{id == 5 && <KhatamAlQuran />}
			</div>
		</DashboardLayout>
	);
}
