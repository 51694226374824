import { useState } from "react";
import { MdOutlineClose, MdOutlineDelete } from "react-icons/md";
import { RiDeleteBinLine, RiEyeLine } from "react-icons/ri";
import IconButton from "./icon_button";
import axios from "axios";
import ApiService from "../api/app.api";
import { DateTime } from "luxon";

const DeleteModal = ({ isOpen, onClose, onDelete, onOpen }) => {
	return (
		<div>
			<IconButton
				icon={MdOutlineDelete}
				label="delete"
				onClick={onOpen}
				color="text-red-500"
				size={20}
			/>
			{isOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-16 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8">
						<h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
						<p className="mb-4">Are you sure you want to delete this item?</p>
						<div className="flex justify-end mt-4">
							<button
								className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg mr-2"
								onClick={onClose}>
								Cancel
							</button>
							<button
								className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
								onClick={onDelete}>
								Delete
							</button>{" "}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
const api = new ApiService();
const ImageGrid = ({ groupedImages, openModal, refetch, user }) => {
	const [open, setIsOpen] = useState(false);
	const [success, setSuccess] = useState({});
	const handlePreview = async () => {};
	// const handleDelete = async (id) => {
	// 	console.log("ID DELETE", id);
	// 	const res = await api.deleteRequest(`api/v1/well-cards/${id}`);
	// 	console.log("RES DELETE", res);
	// 	onClose();
	// };
	const [selectedImage, setSelectedImage] = useState(null);

	const handleImageClick = (image) => {
		setSelectedImage(image);
		// Logic to open modal and perform actions
	};
	const onClose = () => {
		setIsOpen(false);
	};
	const onOpen = () => {
		setIsOpen(true);
	};
	const handleDeleteImg = async () => {
		const res = await axios.delete(
			`${process.env.REACT_APP_API_URL}/api/v1/well-cards/${selectedImage.id}`,
			{
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
		);
		const data = res.data;
		console.log("DATA");
		delete data.data;
		console.log("DATA DATA", data);
		setSuccess(data);
		setSelectedImage(null);
		setSuccess(null);
		refetch();
	};
	return (
		<div>
			{groupedImages.map((group, index) => (
				<div key={index}>
					<hr className="my-4" /> {/* Horizontal divider */}
					<p className="mb-2 font-semibold">{group.group}</p>{" "}
					{/* Upload date */}
					<div className="container grid">
						<div className="space-y-2 p-8 lg:space-y-0 lg:grid lg:gap-3 md:grid-cols-3 lg:grid-cols-4">
							{group.images.map((image, imageIndex) => (
								<div
									key={imageIndex}
									className="cursor-pointer shadow-2xl"
									onClick={openModal}>
									<img
										src={`https://shifa-bckt.s3.amazonaws.com/${image.imageUrl}`}
										alt={image.name}
										className="rounded-lg inset-0
                                        w-full h-auto group-hover:opacity-70"
										onClick={() => handleImageClick(image)}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			))}
			{selectedImage && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="w-[50%] bg-white p-4 rounded">
						<div
							className={`flex  my-2  ${
								success ? "justify-between" : "justify-end"
							}`}>
							{success && (
								<div className="bg-emerald-100 py-2 px-1 rounded ">
									<p className="text-emerald-600">{success.message}</p>
								</div>
							)}

							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={() => {
									setSelectedImage(null);
									setSuccess(null);
									refetch();
								}}
								color="text-slate-500"
								size={20}
							/>
						</div>
						<img
							src={`https://shifa-bckt.s3.amazonaws.com/${selectedImage.imageUrl}`}
							alt={selectedImage.name}
							className="w-full h-auto rounded-lg"
						/>
						<div className="mt-2">
							<p>Name:{selectedImage.name}</p>
							<p>
								Uploaded:{" "}
								{DateTime.fromISO(selectedImage.createdAt).toLocaleString({
									month: "2-digit",
									day: "2-digit",
									year: "numeric",
								})}
							</p>
						</div>
						<div className="flex justify-between my-4 rounded-lg">
							<button
								className="text-gray-500 hover:text-gray-900 focus:outline-none"
								onClick={() => {
									// Logic for delete button
								}}>
								<IconButton
									icon={MdOutlineDelete}
									label="delete"
									onClick={handleDeleteImg}
									color="text-red-500"
									size={20}
								/>
							</button>
							{/* Additional buttons or actions */}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ImageGrid;
