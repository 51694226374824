import React, { useState, useEffect } from "react";

const Toast = ({ message, duration = 3000, onClose }) => {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setVisible(true);

		const timer = setTimeout(() => {
			setVisible(false);
			if (onClose) {
				onClose();
			}
		}, duration);

		return () => {
			clearTimeout(timer);
		};
	}, [duration, onClose]);

	if (!visible) {
		return null;
	}

	return (
		<div className="fixed bottom-0 right-0 mb-4 mr-4 p-4 bg-gray-800 text-white rounded-md shadow-lg">
			{message}
		</div>
	);
};

export default Toast;
