import React from 'react';

const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const InitialsAvatar = ({ firstname, lastname }) => {
    const initials = `${firstname[0].toUpperCase()}${lastname[0].toUpperCase()}`;
    const bgColor = generateRandomColor();

    return (
        <div
            className="  rounded-full text-white font-bold text-xl h-12 w-12 flex justify-center items-center"
            style={{ backgroundColor: bgColor }}
        >
            {initials}
        </div>
    );
};

export default InitialsAvatar;