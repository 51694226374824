import React from "react";
import folder from "../../../assets/folder.png";
import { Link } from "react-router-dom";

const FolderScreen = () => {
	return (
		<div className="flex flex-wrap">
			<div className="w-1/10 p-4">
				<Link to={{ pathname: "/invites/1", search: "?invite=eisal-thawab" }}>
					<div className="bg-gray-300 rounded-md p-4">
						<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
						<p className="text-center">Eisal Thawab</p>
					</div>
				</Link>
			</div>
			<div className="w-1/10 p-4">
				<Link
					to={{
						pathname: "/invites/2",
						search: "?invite=40th-anivasiries",
					}}>
					{" "}
					<div className="bg-gray-300 rounded-md p-4">
						<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
						<p className="text-center">40th Aniversary</p>
					</div>
				</Link>
			</div>
			<div className="w-1/10 p-4">
				<Link
					to={{
						pathname: "/invites/2",
						search: "?invite=1yr-anivasiries",
					}}>
					{" "}
					<div className="bg-gray-300 rounded-md p-4">
						<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
						<p className="text-center">1Year Aniversary</p>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default FolderScreen;
