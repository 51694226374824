import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../pages/layouts/admin_layout";
import React, { useState } from "react";
import emptyImage from "../../../assets/empty.jpg";
import { getCountry } from "../../../utils/get_country";
import axios from "axios";
import { updateUser } from "../../../store/actions/actions";
import { Link } from "react-router-dom";
import InputField from "../../../components/input_field";

const UserProfile = () => {
	const user = useSelector((st) => st.user).user;
	const dispatch = useDispatch();

	const [password, setPassWord] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const handleChangePassword = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("password", password);
		formData.append("newPassword", newPassword);
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/api/v1/users/change-password`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
		);
		console.log("RESPONSE IMAGE", response);
	};
	const handleUploadImage = async (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append("profile-image", file);
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/api/v1/users/profile-image`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${user.token}`,
					"Content-Type": "multipart/form-data",
				},
			}
		);
		console.log("RESPONSE IMAGE", response);
		console.log("USER PROFILE", user);
		const updatedUser = { ...user, imageUrl: response.data.data.user.imageUrl };
		console.log("UPDATED USER PROFILE", updatedUser);
		dispatch(updateUser(updatedUser));
	};
	return (
		<DashboardLayout>
			<div className="bg-gray-100 min-h-screen p-8">
				<div className="max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
					<div className="px-6 py-4">
						<div className="flex items-center justify-center">
							<div className="relative drop-shadow-2">
								<img
									src={
										user.imageUrl
											? `https://shifa-bckt.s3.amazonaws.com/${user.imageUrl}`
											: emptyImage
									}
									alt="profile"
									className="h-32 w-32 rounded-full border border-emerald-300"
								/>
								<label
									htmlFor="profile"
									className="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-emerald-500 text-white hover:bg-opacity-90 sm:bottom-2 sm:right-2">
									<svg
										className="fill-current"
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM5.56219 2.33329L4.5687 3.82353C4.46051 3.98582 4.27837 4.08329 4.08333 4.08329H1.75C1.59529 4.08329 1.44692 4.14475 1.33752 4.25415C1.22812 4.36354 1.16667 4.51192 1.16667 4.66663V11.0833C1.16667 11.238 1.22812 11.3864 1.33752 11.4958C1.44692 11.6052 1.59529 11.6666 1.75 11.6666H12.25C12.4047 11.6666 12.5531 11.6052 12.6625 11.4958C12.7719 11.3864 12.8333 11.238 12.8333 11.0833V4.66663C12.8333 4.51192 12.7719 4.36354 12.6625 4.25415C12.5531 4.14475 12.4047 4.08329 12.25 4.08329H9.91667C9.72163 4.08329 9.53949 3.98582 9.4313 3.82353L8.43781 2.33329H5.56219Z"
											fill=""
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.00004 5.83329C6.03354 5.83329 5.25004 6.61679 5.25004 7.58329C5.25004 8.54979 6.03354 9.33329 7.00004 9.33329C7.96654 9.33329 8.75004 8.54979 8.75004 7.58329C8.75004 6.61679 7.96654 5.83329 7.00004 5.83329ZM4.08337 7.58329C4.08337 5.97246 5.38921 4.66663 7.00004 4.66663C8.61087 4.66663 9.91671 5.97246 9.91671 7.58329C9.91671 9.19412 8.61087 10.5 7.00004 10.5C5.38921 10.5 4.08337 9.19412 4.08337 7.58329Z"
											fill=""
										/>
									</svg>
									<input
										type="file"
										name="profile"
										id="profile"
										className="sr-only"
										onChange={handleUploadImage}
									/>
								</label>
							</div>
						</div>
						<div className="text-center mt-4">
							<h2 className="text-lg font-semibold text-gray-800">{`${user.firstname} ${user.lastname}`}</h2>
							{/* <p className="text-sm text-gray-600">Software Engineer</p> */}
						</div>
						<div className="mt-6">
							<div className="flex items-center justify-between">
								<div className="text-xs text-gray-500 uppercase">Location</div>
								<div className="text-sm text-gray-900">
									{getCountry(user.country)}
								</div>
							</div>
							<div className="flex items-center justify-between mt-2">
								<div className="text-xs text-gray-500 uppercase">Email</div>
								<div className="text-sm text-gray-900">{user.email}</div>
							</div>
							{/* <div className="flex items-center justify-between mt-2">
								<div className="text-xs text-gray-500 uppercase">Website</div>
								<div className="text-sm text-gray-900">www.example.com</div>
							</div> */}
							<div className="flex items-center justify-between mt-2">
								<div className="text-xs text-gray-500 uppercase">Phone</div>
								<div className="text-sm text-gray-900">
									{user.mobileNumber ? user.mobileNumber : "N/A"}
								</div>
							</div>
						</div>
					</div>
					{/* <div className="bg-gray-200 px-6 py-4">
						<div className="text-sm text-gray-700">Bio</div>
						<p className="text-sm text-gray-900 mt-2">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
							tincidunt leo quis orci gravida, ut vestibulum elit viverra.
							Nullam non efficitur massa. Mauris rhoncus odio at lectus commodo,
							id suscipit sem egestas. Nunc vitae mi sem.
						</p>
					</div> */}
					{/* <div className="flex items-center justify-end px-6 py-4">
						<Link to={"/settings"}>
							{" "}
							<button className="bg-emerald-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
								Edit Profile
							</button>
						</Link>
					</div> */}
				</div>{" "}
				<div className="max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg my-4 ">
					<div className="col-span-5 xl:col-span-3">
						<div className="bg-white shadow-lg rounded">
							<div className="border-b  py-4 px-7">
								<h3 className="font-medium text-slate-600">Update Password</h3>
							</div>
							<div className="p-7">
								<form onSubmit={handleChangePassword}>
									<div className="flex justify-between">
										<InputField
											label={"Current Password"}
											placeholder={"current password"}
											classStyles="w-full px-2"
											value={password}
											type="password"
											handleOnChange={(e) => setPassWord(e.target.value)}
										/>{" "}
										<InputField
											label={"New Passowrd"}
											placeholder={"new password"}
											classStyles="w-full px-2"
											value={newPassword}
											type="password"
											handleOnChange={(e) => setNewPassword(e.target.value)}
										/>
									</div>

									<div className="flex justify-end gap-4">
										<Link to={"/"}>
											<button className="bg-emerald-500 text-whiten rounded  py-2 px-4  uppercase">
												Cancel
											</button>
										</Link>

										<button
											className="bg-emerald-500 text-whiten rounded  py-2 px-4  uppercase"
											type="submit">
											Update
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					{/* <div className="col-span-5 xl:col-span-2">
						<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
							<div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
								<h3 className="font-medium text-black dark:text-white">
									Your Photo
								</h3>
							</div>
							<div className="p-7">
								<form action="#">
									<div className="mb-4 flex items-center gap-3">
										<div className="h-14 w-14 rounded-full">
											<img
												src={
													user.imageUrl
														? `https://shifa-bckt.s3.amazonaws.com/${user.imageUrl}`
														: UserOne
												}
												alt="User"
											/>
										</div>
										<div>
											<span className="mb-2 text-black ">Edit your photo</span>
											<span className="flex gap-2">
												<button className="text-sm hover:text-primary">
													Delete
												</button>
												<button className="text-sm hover:text-primary">
													Update
												</button>
											</span>
										</div>
									</div>

									<div
										id="FileUpload"
										className="relative mb-6 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5">
										<input
											type="file"
											accept="image/*"
											className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
										/>
										<div className="flex flex-col items-center justify-center space-y-3">
											<span className="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
														fill="#3C50E0"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
														fill="#3C50E0"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
														fill="#3C50E0"
													/>
												</svg>
											</span>
											<p>
												<span className="text-primary">Click to upload</span> or
												drag and drop
											</p>
											<p className="mt-1">SVG, PNG, JPG or GIF</p>
											<p>(max, 800 X 800px)</p>
										</div>
									</div>

									<div className="flex justify-end gap-4">
										<button
											className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
											type="submit">
											Cancel
										</button>
										<button
											className="flex justify-center rounded bg-emerald-500 py-2 px-6 font-medium text-gray hover:bg-opacity-70"
											type="submit">
											Save
										</button>
									</div>
								</form>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</DashboardLayout>
	);
};

export default UserProfile;
