import React, { useEffect, useState } from "react";
import ApiService from "../../api/app.api";
import InputField from "../../components/input_field";
import Modal from "../../components/modal.component";
import Table, {
	ActionCollumn,
	DuaArabicCell,
	SelectColumnFilter,
} from "./components/user_table";
import DashboardLayout from "../layouts/admin_layout";
import Breadcrumb from "../../components/bread_crumb";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import folder from "../../assets/folder.png";

const api = new ApiService();
export default function UserRequests() {
	const [fullnames, setNames] = useState("");
	const [isender, setSender] = useState("");
	const [itime, setTime] = useState("");
	const [idate, setDate] = useState("");
	const [phone, setPhone] = useState("");
	const [ilocation, setLocation] = useState("");
	const [data, setData] = useState([]);
	const [selectedOption, setInviteType] = useState("");
	const user = useSelector((st) => st.user).user;
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await api.getRequest(
				"api/v1/send-invitations?limit=50",
				user.token
			);
			console.log("INVITES", response);
			const res = response.data.sendInvitations;
			setData(res);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Sender",
				accessor: "sender",
				Cell: DuaArabicCell,
			},
			{
				Header: "Time",
				accessor: "time",
			},
			{
				Header: "Date",
				accessor: "date",
				Filter: SelectColumnFilter, // new
				filter: "includes",
			},
			{
				Header: "Location",
				accessor: "location",
			},
			{
				Header: "Phonenumber",
				accessor: "phonenumber",
				// Filter: SelectColumnFilter, // new
				// filter: "includes",
			},
			{
				Header: "Type",
				accessor: "type",
				Filter: SelectColumnFilter, // new
				filter: "includes",
			},
			{
				Header: "Actions",
				Cell: ActionCollumn,
			},
		],
		[]
	);
	// const data = React.useMemo(() => getDuas(), []);
	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Requests", path: "/requests" },
	];
	const handleCreate = async () => {
		const name = fullnames;
		const sender = isender;
		const time = itime;
		const date = idate;
		const location = ilocation;
		const phonenumber = phone;
		const type = selectedOption;
		const body = JSON.stringify({
			name,
			sender,
			time,
			date,
			location,
			phonenumber,
			type,
		});
		const data = await api.postRequest(`api/v1/send-invitations/invite`, body);
		console.log("DATA", data);
		// closeModal();
	};
	return (
		<DashboardLayout>
			{" "}
			<div className="flex justify-between items-center mr-16 my-2">
				<Breadcrumb routes={routes} />
				<div className="flex  justify-between justify-items-center"></div>
			</div>
			<div className="mt-6 mx-8">
				{/* <Table columns={columns} data={data} /> */}
				<div className="flex flex-wrap">
					<div className="w-1/10 p-4">
						<Link
							to={{ pathname: "/requests/1", search: "?request=dua-e-shifa" }}>
							<div className="bg-gray-300 rounded-md p-4">
								<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
								<p className="text-center">Dua-e-Shifa</p>
							</div>
						</Link>
					</div>
					<div className="w-1/10 p-4">
						<Link
							to={{
								pathname: "/requests/2",
								search: "?request=well-cards",
							}}>
							{" "}
							<div className="bg-gray-300 rounded-md p-4">
								<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
								<p className="text-center">Well-Cards</p>
							</div>
						</Link>
					</div>
					<div className="w-1/10 p-4">
						<Link
							to={{
								pathname: "/requests/3",
								search: "?request=salatul-wahshat",
							}}>
							{" "}
							<div className="bg-gray-300 rounded-md p-4">
								<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
								<p className="text-center">Salatul Wahshat</p>
							</div>
						</Link>
					</div>
					<div className="w-1/10 p-4">
						<Link
							to={{
								pathname: "/requests/4",
								search: "?request=surat-al-fatiha",
							}}>
							{" "}
							<div className="bg-gray-300 rounded-md p-4">
								<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
								<p className="text-center">Surat al-Fatiha</p>
							</div>
						</Link>
					</div>
					<div className="w-1/10 p-4">
						<Link
							to={{
								pathname: "/requests/5",
								search: "?request=Khatam-al-Quaran",
							}}>
							{" "}
							<div className="bg-gray-300 rounded-md p-4">
								<img src={folder} alt="Folder" className="w-12 mx-auto mb-2" />
								<p className="text-center">Khatam al quran</p>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</DashboardLayout>
	);
}
