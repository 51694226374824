import React, { useState } from "react";

const TextArea = ({ text, name, handleChange, readOnly, title }) => {
	return (
		<div className="p-4">
			<h1 className="mb-2">{title}</h1>
			<textarea
				className="w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
				value={text}
				name={name}
				onChange={handleChange}
				placeholder="Enter transaltion"
				readOnly={readOnly}
			/>
		</div>
	);
};

export default TextArea;
