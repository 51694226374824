import React from "react";
import { MdOutlineDelete } from "react-icons/md";
import IconButton from "./icon_button";

const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, onOpen }) => {
	return (
		<div>
			<IconButton
				icon={MdOutlineDelete}
				label="delete"
				onClick={onOpen}
				color="text-danger"
			/>
			{isOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8">
						<h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
						<p className="mb-4">Are you sure you want to delete this item?</p>
						<div className="flex justify-end mt-4">
							<button
								className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg mr-2"
								onClick={onClose}>
								Cancel
							</button>
							<button
								className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
								onClick={onDelete}>
								Delete
							</button>{" "}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DeleteConfirmationModal;
