import React from "react";

export default function InputField({
	name,
	label,
	type,
	autoComplete,
	required,
	value,
	handleOnChange,
	placeholder,
	classStyles,
}) {
	return (
		<div className={"mb-4 " + classStyles}>
			<label
				htmlFor={name}
				className="block text-sm font-medium text-gray-700 ">
				{label}
			</label>
			<div className="mt-1">
				<input
					id={name}
					name={name}
					type={type}
					autoComplete={autoComplete}
					required={required}
					value={value}
					onChange={handleOnChange}
					placeholder={placeholder}
					className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
				/>
			</div>
		</div>
	);
}
