import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import Breadcrumb from "../../components/bread_crumb";
import ImageGrid from "../../components/image_grid";
import Modal from "../../components/modal.component";
import groupImagesByDate from "../../utils/group_Images";
import DashboardLayout from "../layouts/admin_layout";

function convertBytesToMB(bytes) {
	const megabytes = bytes / (1024 * 1024);
	return megabytes.toFixed(2);
}
export default function WellCards() {
	const [images, setImages] = useState([]);
	const [file, setFile] = useState([]);
	const user = useSelector((st) => st.user).user;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [isImgModalOpen, setImgModalOpen] = useState(false);
	useEffect(() => {
		handleFetch();
	}, []);
	console.log(isImgModalOpen);
	const handleFetch = async () => {
		console.log("TOKEN", user.token);
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/v1/well-cards?limit=20`,
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			const data = res.data;
			console.log("WELL CARDS", data);
			if (data.statusCode === 401) {
				setError(res);
			} else {
				const list = groupImagesByDate(data.data?.wellCards);
				console.log("IMAGES", list);
				setImages(list);
			}
		} catch (e) {
			setError(e.response.data);
			console.log("ERRor", e);
		}
	};
	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.map((file) => {
			console.log("FILE", file);
			setFile((prevState) => [...prevState, file]);
		});
	}, []);
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({ accept: "image/*", onDrop });
	const handleUpload = async () => {
		const formData = new FormData();
		formData.append("image", file[0]);
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/api/v1/well-cards`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
		);
		let newImage = res.data.data.wellCard;
		delete newImage.deletedAt;
		console.log("RES", res);
		console.log("RES,WELL CARDS", newImage);
		handleFetch();
		setFile([]);
		closeModal();
	};
	const files = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {convertBytesToMB(file.size)} MB
		</li>
	));
	const openModal = () => {
		setIsModalOpen(true);
	};
	const openImgModal = () => {
		setImgModalOpen(true);
	};
	const closeImgModal = () => {
		setImgModalOpen(false);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const routes = [
		{ name: "Dashboard", path: "/" },
		{ name: "Well Cards", path: "/well-cards" },
	];
	return (
		<DashboardLayout>
			<div className="flex justify-between items-center mx-8 mt-2">
				<Breadcrumb routes={routes} />
				<div className="flex  justify-between justify-items-center">
					<Modal
						action={"Upload"}
						handleSubmit={handleUpload}
						closeModal={closeModal}
						isModalOpen={isModalOpen}
						openModal={openModal}
						label={"Upload"}
						title={"Upload"}>
						<div
							{...getRootProps()}
							className={`border-2 border-dashed rounded-lg p-4 ${
								isDragActive ? "bg-gray-100" : "bg-white"
							}`}>
							<input {...getInputProps()} />
							{isDragActive ? (
								<p className="text-gray-600">Drop the files here...</p>
							) : (
								<p className="text-gray-600">
									Drag 'n' drop some files here, or click to select files
								</p>
							)}
						</div>
						<aside>
							<h4>Files</h4>
							<ul>{files}</ul>
						</aside>
					</Modal>
				</div>
			</div>
			<div className="mt-6 mx-8">
				{error && error.statusCode === 401 && (
					<div className="p-y-2 p-x-1 bg-red-400 w-auto">
						<p className="text-white">
							{" "}
							You are not authorized to this resource
						</p>
					</div>
				)}
				<ImageGrid groupedImages={images} refetch={handleFetch} user={user} />
			</div>
			{}
			{/* <Modal
				openModal={openImgModal}
				closeModal={closeImgModal}
				isModalOpen={isImgModalOpen}
				label={"IMAGE"}
				title={"Image"}></Modal> */}
		</DashboardLayout>
	);
}
