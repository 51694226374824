import React, { useEffect, useState } from "react";
import DashboardLayout from "../layouts/admin_layout";
import ApiService from "../../api/app.api";
import axios from "axios";

const api = new ApiService();
export default function Forms() {
	const [forms, setForms] = useState();
	useEffect(() => {
		handleFetchForms();
	}, []);
	const handleFetchForms = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/v1/forms`
		);
		console.log("FORMS", response);
	};
	return (
		<DashboardLayout>
			<div>Forms</div>
		</DashboardLayout>
	);
}
