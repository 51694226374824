import { useCallback, useState } from "react";
import ApiService from "../api/app.api";
import DropdownSelect from "./dropdown_select";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import IconButton from "./icon_button";
import { MdOutlineClose } from "react-icons/md";

function convertBytesToMB(bytes) {
	const megabytes = bytes / (1024 * 1024);
	return megabytes.toFixed(2);
}
const api = new ApiService();
const AddCategory = ({ refetch }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [error, setError] = useState("");
	const [file, setFile] = useState([]);
	const [selectedOption, setSelectedOption] = useState("");
	const [title, setTitle] = useState("");
	const user = useSelector((st) => st.user).user;

	const handleCreate = async () => {
		if (file.length === 0) {
			setError("Please select an Icon for this Dua");
		} else {
			const formData = new FormData();
			const isParent = true;
			formData.append("asset", file[0]);
			formData.append("title", title);
			formData.append("isParent", isParent);
			const data = await axios.post(
				`${process.env.REACT_APP_API_URL}/api/v1/duas/categories`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			console.log("DATA", data);

			closeModal();
			refetch();
			setFile([]);
			setTitle("");
			setSelectedOption("");
		}
	};
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setFile([]);
		setTitle("");
		setSelectedOption("");
	};
	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};
	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.map((file) => {
			console.log("FILE", file);
			setFile((prevState) => [...prevState, file]);
		});
	}, []);
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({ accept: "image/*", onDrop });

	const files = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {convertBytesToMB(file.size)} MB
		</li>
	));
	return (
		<div>
			<button
				className="bg-emerald-500 p-2 text-white font-medium rounded-md uppercase mx-4"
				onClick={openModal}>
				+ add
			</button>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[48%] h-auto">
						<div className="flex justify-between">
							<h2 className="text-2xl font-bold mb-4">
								Create Dua or Tawassol
							</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={closeModal}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<hr />
						<div className="py-2 px-2">
							<DropdownSelect
								selectedOption={selectedOption}
								handleOptionChange={handleOptionChange}
							/>
							<div className="flex items-center space-x-2 py-2">
								<label
									htmlFor="dropdown"
									className="text-sm font-medium text-gray-700">
									Title:
								</label>
								<div className="mt-1">
									<input
										id="name"
										name="name"
										type="text"
										required
										value={title}
										onChange={(event) => setTitle(event.target.value)}
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									/>
								</div>
							</div>
							<div className="flex items-center space-x-2 py-2">
								<label
									htmlFor="dropdown"
									className="text-sm font-medium text-gray-700">
									Icon:
								</label>{" "}
								<div
									{...getRootProps()}
									className={`border-2 border-dashed rounded-lg p-4 ${
										isDragActive ? "bg-gray-100" : "bg-white"
									}`}>
									<input {...getInputProps()} />
									{isDragActive ? (
										<p className="text-gray-600">Drop the files here...</p>
									) : (
										<p className="text-gray-600">
											Drag 'n' drop some files here, or click to select files
										</p>
									)}
								</div>
							</div>

							<aside>
								<h4>Files</h4>
								<ul>{files}</ul>
							</aside>
							{error && (
								<div className="mt-4 ">
									<div
										className="flex justify-between bg-meta-7 border border-red-400 text-red-700 px-4 py-3 rounded relative"
										role="alert">
										<span className="block sm:inline">{error}</span>
										<span
											className="hover:cursor-pointer"
											onClick={() => setError("")}>
											X
										</span>
									</div>
								</div>
							)}
						</div>
						<div className="mt-auto flex justify-end">
							<button
								className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-8 rounded mr-2 uppercase"
								onClick={closeModal}>
								Close
							</button>
							<button
								className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-8 rounded uppercase"
								onClick={handleCreate}>
								create
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AddCategory;
