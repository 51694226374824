import React from "react";
import DashboardLayout from "../layouts/admin_layout";
import UserStats from "../../components/user_stats";
import TimeseriesChart from "./components/timeseries_chart";
import CardOne from "../../components/card_one";
import CardTwo from "../../components/card_two";
import CardThree from "../../components/card_thre";
import CardFour from "../../components/card_four";
import Calender from "../../components/calender";
import ChartTwo from "../../components/chart_two";
import HCalendar from "../../components/hijiri-calender";
import { useSelector } from "react-redux";

export default function Dashboard() {
	const user = useSelector((st) => st.user).user;
	console.log("CURRENT USER", user);
	return (
		<DashboardLayout>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
				<CardOne user={user} />
				<CardTwo user={user} />
				<CardThree user={user} />
				<CardFour user={user} />
			</div>
			{/*
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-4 xl:grid-cols-2 2xl:gap-2">
				<HCalendar />
				<Calender />
			</div> */}
			<div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-8 2xl:gap-8">
				<TimeseriesChart />
				<ChartTwo />
			</div>
		</DashboardLayout>
	);
}
