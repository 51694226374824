import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
	const user = useSelector((state) => state.user).user;
	let location = useLocation();
	console.log("USER===>", user);
	if (!user.id) {
		return <Navigate to="/signin" state={{ from: location }} replace />;
	} else if (!user.isVerified) {
		return <Navigate to="/confirm-otp" state={{ from: location }} replace />;
	}
	return children;
};

export default ProtectedRoute;
