import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../api/app.api";
import logo from "../../assets/logo.png";
import CountrySelector from "../../components/country_selector";
import { loginUser } from "../../store/actions/actions";
import { COUNTRIES } from "../lib/countries";

const api = new ApiService();
const SignUp = () => {
	const [firstname, setFirstName] = useState("");
	const [lastname, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [country, setCountry] = useState("BE");
	const [dob, setDob] = useState("");
	const [error, setError] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const router = useRouter();

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			let dateOfBirth = dob;
			const body = JSON.stringify({
				firstname,
				lastname,
				email,
				password,
				country,
				dateOfBirth,
			});

			const response = await api.signup("api/v1/auth/signUp", body);

			if (!response.status) {
				setError(response.message);
				return;
			}

			console.log("USER", response.data);
			dispatch(loginUser(response.data));
			navigate("/");
		} catch (error) {
			console.error("ER", error);
			setError("Something went wrong. Please try again later.");
		}
	};

	return (
		<div className="h-auto  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
					{" "}
					<div className="sm:mx-auto sm:w-full sm:max-w-md mb-10">
						<img
							className="mx-auto h-12 w-auto"
							src={logo}
							alt="Logo"
							width={60}
							height={60}
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Sign up for an account
						</h2>
					</div>
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="first-name"
								className="block text-sm font-medium text-gray-700">
								First name
							</label>
							<div className="mt-1">
								<input
									id="first-name"
									name="first-name"
									type="text"
									autoComplete="given-name"
									required
									value={firstname}
									onChange={(event) => setFirstName(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="last-name"
								className="block text-sm font-medium text-gray-700">
								Last name
							</label>
							<div className="mt-1">
								<input
									id="last-name"
									name="last-name"
									type="text"
									autoComplete="family-name"
									required
									value={lastname}
									onChange={(event) => setLastName(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						</div>{" "}
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700">
								Email address
							</label>
							<div className="mt-1">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									value={email}
									onChange={(event) => setEmail(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700">
								Password
							</label>
							<div className="mt-1">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="new-password"
									required
									value={password}
									onChange={(event) => setPassword(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700">
								Date Of Birth
							</label>
							<div className="mt-1">
								<input
									id="password"
									name="dob"
									type="date"
									autoComplete="new-password"
									required
									value={dob}
									onChange={(event) => setDob(event.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700">
								Country
							</label>
							<div className="mt-1">
								<CountrySelector
									id={"country-selector"}
									open={isOpen}
									onToggle={() => setIsOpen(!isOpen)}
									onChange={setCountry}
									selectedValue={COUNTRIES.find(
										(option) => option.value === country
									)}
								/>
							</div>
						</div>
						{error && (
							<div className="mt-4">
								<div
									className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
									role="alert">
									<span className="block sm:inline">{error}</span>
								</div>
							</div>
						)}
						<div>
							<button
								type="submit"
								className="bg-emerald-500 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Sign up
							</button>
						</div>
					</form>
					<div className="mt-4">
						<div className="relative">
							<div
								className="absolute inset-0 flex items-center"
								aria-hidden="true">
								<div className="w-full border-t border-gray-300"></div>
							</div>
							<div className="relative flex justify-center text-sm">
								<span className="px-2 bg-white text-slate-500">
									Or continue to signin
								</span>
							</div>
						</div>

						<div className="flex items-center justify-between mt-6">
							<div>
								<p>You already have an account?</p>
							</div>

							<div className="text-sm">
								<Link
									to={"/signin"}
									className="font-medium text-indigo-600 hover:text-indigo-500">
									sign in
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
