import React, { useState } from "react";
import DropdownSelect from "./dropdown_select";
import RichTextEditor from "./arabic_rich_texteditor";
import TextArea from "./text_area";
import ApiService from "../api/app.api";
import IconButton from "./icon_button";
import { MdOutlineClose } from "react-icons/md";

const api = new ApiService();
export default function CreateModal({ refetch, id, user }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState("");
	const [engContent, setEnContent] = useState("");
	const [editorContent, setEditorContent] = useState("");
	const [title, setTitle] = useState("");
	const [translatedText, setTranslatedText] = useState("");

	// const handleChange = (event) => {
	// 	setText(event.target.value);
	// };

	const handleChangeArabic = (event) => {
		setEditorContent(event.target.value);
	};

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const handleTextChange = (event) => {
		setEnContent(event.target.value);
		// setTranslatedText(event.target.value);
	};
	const handleTranslatedText = (event) => {
		setTranslatedText(event.target.value);
	};
	const handleCreate = async () => {
		const name = title;
		const duaArabic = editorContent;
		const duaEnglish = engContent;
		const translation = translatedText;
		const body = JSON.stringify({
			name,
			duaArabic,
			duaEnglish,
			translation,
		});
		const data = await api.postRequestwithBearer(
			`api/v1/duas/add/${selectedOption}/${id}`,
			body,
			user.token
		);
		console.log("DATA", data);

		closeModal();
		refetch();
	};
	return (
		<div>
			<button
				className="bg-emerald-500 p-2 text-white font-medium rounded-md uppercase mx-4"
				onClick={openModal}>
				+ create
			</button>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[48%] h-auto">
						<div className="flex justify-between">
							<h2 className="text-2xl font-bold mb-4">
								Create Dua or Tawassol
							</h2>
							<IconButton
								icon={MdOutlineClose}
								label="delete"
								onClick={closeModal}
								color="text-slate-500"
								size={20}
							/>
						</div>

						<hr />
						<div className="py-2 px-2">
							<DropdownSelect
								selectedOption={selectedOption}
								handleOptionChange={handleOptionChange}
							/>
							<div className="flex items-center space-x-2 py-2">
								<label
									htmlFor="dropdown"
									className="text-sm font-medium text-gray-700">
									Title:
								</label>
								<div className="mt-1">
									<input
										id="name"
										name="name"
										type="text"
										required
										value={title}
										onChange={(event) => setTitle(event.target.value)}
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									/>
								</div>
							</div>
							<TextArea
								handleChange={handleChangeArabic}
								text={editorContent}
								title="Dua Arabic"
								name="duaarabic"
							/>
							<TextArea
								handleChange={handleTextChange}
								text={engContent}
								title="Dua English"
								name="duaeng"
							/>
							<TextArea
								name="translation"
								title="Translation"
								text={translatedText}
								handleChange={handleTranslatedText}
							/>
						</div>

						<div className="mt-auto flex justify-end">
							<button
								className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-8 rounded mr-2 uppercase"
								onClick={closeModal}>
								Close
							</button>
							<button
								className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-8 rounded uppercase"
								onClick={handleCreate}>
								create
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
