import React, { useEffect, useState } from "react";
import DropdownSelect from "./dropdown_select";
import RichTextEditor from "./arabic_rich_texteditor";
import TextArea from "./text_area";
import ApiService from "../api/app.api";
import IconButton from "./icon_button";
import { FaEdit, FaEye } from "react-icons/fa";

const api = new ApiService();
export default function ViewModal({ data }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState("");
	const [engContent, setEnContent] = useState("");
	const [editorContent, setEditorContent] = useState("");
	const [title, setTitle] = useState("");
	const [translatedText, setTranslatedText] = useState("");
	console.log("DATA", data);

	useEffect(() => {
		setSelectedOption(data.duaType);
		setTitle(data.name);
		setEnContent(data.duaEnglish);
		setEditorContent(data.duaArabic);
		setTranslatedText(data.translation);
	}, []);
	const handleChangeArabic = (content) => {
		setEditorContent(content);
	};

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleUpdate = async () => {
		const name = title;
		const duaArabic = editorContent;
		const duaEnglish = engContent;
		const translation = translatedText;
		const body = JSON.stringify({
			name,
			duaArabic,
			duaEnglish,
			translation,
		});
		const res = await api.putRequest(
			`api/v1/duas/${selectedOption}/${data.id}`,
			body
		);
		console.log("DATA", res);
		closeModal();
	};
	return (
		<div>
			<IconButton
				icon={FaEye}
				label="view"
				onClick={() => openModal()}
				color="text-success"
			/>
			{isModalOpen && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 ">
					<div className="bg-white rounded shadow-lg p-8 w-[50%] h-auto">
						<h2 className="text-2xl font-bold mb-4">Update Dua or Tawassol</h2>
						<hr />
						<div className="py-4 px-2">
							<DropdownSelect
								selectedOption={selectedOption}
								handleOptionChange={handleOptionChange}
							/>
							<div className="flex items-center space-x-2 py-2">
								<label
									htmlFor="dropdown"
									className="text-sm font-medium text-gray-700">
									Title:
								</label>
								<div className="mt-1">
									<input
										id="name"
										name="name"
										type="text"
										required
										value={title}
										onChange={(event) => setTitle(event.target.value)}
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									/>
								</div>
							</div>
							<RichTextEditor
								handleChangeArabic={handleChangeArabic}
								editorContent={editorContent}
								title="Dua Arabic"
							/>
							<RichTextEditor
								handleChangeArabic={(value) => setEnContent(value)}
								editorContent={engContent}
								title="Dua English"
							/>
							<TextArea
								text={translatedText}
								name="translation"
								handleChange={(e) => setTranslatedText(e.target.value)}
							/>
						</div>

						<div className="mt-6 flex justify-end">
							<button
								className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-8 rounded mr-2 uppercase"
								onClick={closeModal}>
								Close
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
