import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";
import rootReducer from "./reducers/reducers";

//create store
const storeName = "shifa";
//save to local storage
const saveToLocalStorage = (state) => {
	try {
		const stringState = JSON.stringify(state);
		localStorage.setItem(storeName, stringState);
	} catch (e) {
		throw new Error(e);
	}
};
const loadFromLocalStorage = () => {
	try {
		const stringState = localStorage.getItem(storeName);
		if (stringState === null) return undefined;
		return JSON.parse(stringState);
	} catch (e) {
		throw new Error(e);
	}
};
const middleware = [thunk];

const persistedStorage = loadFromLocalStorage();

let store = configureStore({
	reducer: rootReducer,
	preloadedState: persistedStorage,
	devTools: composeWithDevTools(applyMiddleware(middleware)),
});
store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
