import React from 'react';

const NoPermissionWidget = () => {
  return (
    <div className="flex flex-col items-center justify-center  bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md text-center">
        <h2 className="text-xl font-bold text-red-500 mb-4">Access Denied</h2>
        <p className="text-gray-700 mb-6">
          You do not have sufficient permissions to access this resource. Please contact the administrator if you believe this is an error.
        </p>
        <button
          onClick={() => window.location.href = 'mailto:admin@example.com'}
          className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded"
        >
          Contact Admin
        </button>
      </div>
    </div>
  );
};

export default NoPermissionWidget;
