import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ChangePassword from "./pages/change-password";
import ConfirmOTP from "./pages/confirm-otp";
import Dashboard from "./pages/dashboad";

import Forms from "./pages/dashboad/forms";
import KhatamAlQuran from "./pages/dashboad/khatam_al_quran";
import UserProfile from "./pages/dashboad/profile/user_profile";
import UserRequests from "./pages/dashboad/requests";
import UserSettings from "./pages/dashboad/settings";
import Users from "./pages/dashboad/users";
import Admins from "./pages/dashboad/user_admins.jsx";
import WellCards from "./pages/dashboad/well_cards";
import ChatLayout from "./pages/layouts/chat_layout";
import ProtectedRoute from "./pages/lib/auth_hook";
import LoginPage from "./pages/signin";
import SignUp from "./pages/signup";
import VerifyEmail from "./pages/verify-email";
import Invites from "./pages/dashboad/invites/index";
import InviteFolders from "./pages/dashboad/invites_folders";
import DuasDir from "./pages/dashboad/duas";
import Duas from "./pages/dashboad/dua/[name]/[id]";
import Dua from "./pages/dashboad/dua/[name]/[id]/dua";
import DuaChildren from "./pages/dashboad/dua/[name]";
import Requests from "./pages/dashboad/Request";
import PrivacyPolicy from "./privacy_policy";
import DeleteAccount from "./delete_account";
import Feed from "./pages/dashboad/feeds/Feeds.jsx";
import SupportPage from "./pages/support/index.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <ChatLayout />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/requests"
            element={
              <ProtectedRoute>
                <UserRequests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/requests/:id"
            element={
              <ProtectedRoute>
                <Requests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invites"
            element={
              <ProtectedRoute>
                <InviteFolders />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/invites/:name"
            element={
              <ProtectedRoute>
                <Invites />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tawassol"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/duas"
            element={
              <ProtectedRoute>
                <DuasDir />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dua/:name/:id"
            element={
              <ProtectedRoute>
                <Duas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dua/:name/:child/:id"
            element={
              <ProtectedRoute>
                <Dua />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dua/:name"
            element={
              <ProtectedRoute>
                <DuaChildren />
              </ProtectedRoute>
            }
          />
          <Route
            path="/well-cards"
            element={
              <ProtectedRoute>
                <WellCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Khatam al Quaran"
            element={
              <ProtectedRoute>
                <KhatamAlQuran />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/forms"
            element={
              <ProtectedRoute>
                <Forms />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <UserSettings />
              </ProtectedRoute>
            }
          >
            <Route
              path="/settings/administrators"
              element={
                <ProtectedRoute>
                  <Admins />
                </ProtectedRoute>
              }
            />
          </Route>
		  <Route
            path="/posts"
            element={
              <ProtectedRoute>
                <Feed />
              </ProtectedRoute>
            }
          >
            <Route
              path=":id"
              element={
                <ProtectedRoute>
                  <Admins />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ChangePassword />} />
          <Route path="/confirm-otp" element={<ConfirmOTP />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/delete_account" element={<DeleteAccount />} />
          <Route path="/support" element={<SupportPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
